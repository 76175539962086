import ApiDefault from "../ApiDefault";

const PATH = 'v1/database-feeds';

export default class Feed extends ApiDefault {
  async getList() {
    return this.get(PATH + "/list");
  }

  async delete(id) {
    return this.remove(PATH, { id })
  }

  async create(data) {
    return this.post(PATH, data);
  }

  async getFeed(id) {
    return this.get(PATH + "/" + id);
  }

  async update(id, data) {
    return this.put(PATH + "/" + id, data);
  }

  async subscribe(tabId) {
    return this.post(PATH + "/subscribe", { tabId });
  }
}