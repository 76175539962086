import { styled } from "@mui/material/styles";
import { Tab as MuiTab, tabClasses } from "@mui/material";

export const Tab = styled(MuiTab)(({ theme }) => ({
  color: theme.palette.dark,
  padding: 0,
  minHeight: "unset",
  minWidth: "unset",
  lineHeight: "24px",
  fontSize: "16px",
  textTransform: "capitalize",
  [`&.${tabClasses.selected}`]: {
    color: theme.palette.dark,
  },
  '&:hover': {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.up("md")]: {
    lineHeight: `${24 / 16}vw`,
    fontSize: `${16 / 16}vw`,
  },
}));
