import { Box, IconButton } from "@mui/material";
import { NavLink } from "react-router-dom";
import React from 'react';
import { ArrowTooltip } from "components";
import { useTheme } from "@mui/material/styles";
import useResize from "../../../config/themes/responsive";

export default function MenuItem({ title, to, children }) {
    const theme = useTheme();
    const scaleFactor = useResize();

    return (
        <Box
            sx={{
                marginBottom: "16px",
                color: "black",
                "& .active": {
                    color: "white",
                    backgroundColor: "primary.main",
                },
                [theme.breakpoints.up("md")]: {
                    marginBottom: `${(16 * scaleFactor) / 16}vw`,
                },
            }}
        >
            <ArrowTooltip title={title} placement="right">
                <IconButton
                    to={to}
                    component={NavLink}
                    sx={{
                        height: "36px",
                        width: "36px",
                        [theme.breakpoints.up("md")]: {
                            height: `${(36 * scaleFactor) / 16}vw`,
                            width: `${(36 * scaleFactor) / 16}vw`,
                            padding: `${(8 * scaleFactor) / 16}vw`,
                        },
                    }}
                    color={'inherit'}
                >
                    {children}
                </IconButton>
            </ArrowTooltip>
        </Box>
    );
}

