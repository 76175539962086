import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, IconButton, Menu, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import useResize from "../../config/themes/responsive";

export function HeaderTimeRangePicker({ value, handleDateRangeChange, handleOpen }) {
  const { t } = useTranslation(["translation", "form"]);
  const { user } = useSelector(x => x.auth);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const scaleFactor = useResize();

  const anchorRef = useRef(null);

  const vw = (px) => `${(px * scaleFactor) / 16}vw`;
  const handleButtonClick = (e) => {
    setOpen((prevOpen) => {
      handleOpen(prevOpen);

      return !prevOpen;
    });
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(false);
    handleOpen(true);
  };

  const dateStart = parseDate(value[0]?.toDate());
  const dateEnd = parseDate(value[1]?.toDate());
  const title = (dateStart || dateEnd) 
    ? ((dateStart ? `${t('c')} ${dateStart} ` : '') + (dateEnd ? ` ${t('__to')} ${dateEnd}` : '')) 
      : t('forAllPeriod');

  return <>
    <Typography
        variant="h5"
        ml={{xs: 6, xl: 7.5}}
        sx={(theme) => ({
            fontWeight: "500",
            fontFamily: "Fixel Medium",
            lineHeight: "24px",
            fontSize:{xs:"13px", xl:"16px"},
            color:"newGrey.dark",
            [theme.breakpoints.up("md")]: {
              fontSize: vw(16) + "!important",
              marginLeft: vw(60) + "!important",
              lineHeight: vw(24)
          },
        })}
      >
      {t('period')}:
    </Typography>
    <Button
      endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      disabled={!user.isCrmUser}
      onClick={handleButtonClick}
      ref={anchorRef}
      sx={(theme) => ({
        fontWeight: "500",
        fontSize:{xs:"13px", xl:"16px"},
        textWrap:"nowrap",
        minWidth:"unset",
        p:1.25,
        color:"dark",
        [theme.breakpoints.up("md")]: {
          fontSize: vw(16) + "!important",
          lineHeight: vw(28),
          padding: vw(10),
          '& .MuiButton-endIcon': {
            marginRight: vw(-4),
            marginLeft: vw(8),
            fontSize: vw(16),
            width: vw(20),
            height: vw(20),
            lineHeight: vw(33),
          },
          '& .MuiSvgIcon-root': {
            fontSize: vw(20),
            width: vw(20),
            height: vw(20),
          }
        },
      })}
      variant="text"
    >{title}</Button>
    
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
              '& .MuiPaper-elevation': {
                  minHeight: vw(16),
                  minWidth: vw(16),
                  maxWidth: `calc(100% - ${vw(32)})`,
                  maxHeight: `calc(100% - ${vw(96)})`,
                  top: vw(61),
                  left: vw(841),
                  transformOrigin: `${vw(312)} ${vw(0)}`
              }
          },
      })}
    >
      <LocalizationProvider
          dateAdapter={AdapterDayjs} adapterLocale={'ua'}
      >
        <DateRangeCalendar
          value={value}
          onChange={handleDateRangeChange}
          sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                  '& .MuiPickersArrowSwitcher-root': {
                      padding: `${vw(16)} ${vw(16)} ${vw(8)}`
                  },
                  '& .MuiTypography-subtitle1': {
                      fontSize: vw(16),
                      lineHeight: vw(33),
                  },
                  '& .MuiDayCalendar-weekDayLabel': {
                      fontSize: vw(12),
                      lineHeight: vw(20),
                      width: vw(36),
                      height: vw(40),
                      margin: `${vw(0)} ${vw(2)}`
                  },
                  '& .MuiDayCalendar-slideTransition': {
                      minWidth: vw(312),
                      minHeight: vw(264)
                  },
                  '& .MuiDayCalendar-weekContainer': {
                      margin: `${vw(2)} ${vw(0)}`
                  },
                  '& .MuiDateRangePickerDay-day': {
                      width: vw(36),
                      height: vw(36)
                  }
              },
          })}
        />
      </LocalizationProvider>
      <Box
          sx={(theme) => ({
              display: 'flex',
              justifyContent: 'flex-end',
              mt: -5,
              pr: 1,
              [theme.breakpoints.up("md")]: {
                  paddingRight: vw(8),
                  marginTop: vw(-40),
              },
          })}
      >
        <IconButton aria-label="clear" onClick={() => handleDateRangeChange([null, null])}>
          <DeleteForeverIcon
              sx={(theme) => ({
                  [theme.breakpoints.up("md")]: {
                      top: vw(-1) + '!important',
                      width: vw(24),
                      height: vw(24),
                      fontSize: vw(24),
                      lineHeight: vw(28),
                  },
              })}
          />
        </IconButton>
      </Box>
    </Menu>
  </>
}

function parseDate(date) {
  let result = "";
  if (date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    result = `${day}.${month}`;
  }

  return result;
}