import { InputAdornment, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useMask } from '@react-input/mask';
import useResize from "../../config/themes/responsive";

export const InputPhoneMask = ({autoFocus, disabled, endAdornment, field, formik, label, multiline, required, rows }) => {
    const scaleFactor = useResize();
    const placeholder = '+38(___)___-__-__';

    const vw = (px) => `${(px * scaleFactor) / 16}vw`;
    const inputRef = useMask({
        mask: "+38(___)___-__-__",
        replacement: { _: /\d/ },
        placeholder: placeholder
    });

    const [phoneNumber, setPhoneNumber] = useState(formik.values[field])

    return <>
        <Typography
            className={required && "label"}
            variant="h4" mt={{ xs: 1.5, md: 3.75 }}
            sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                    marginTop: vw(30),
                    fontSize: vw(14),
                    lineHeight: vw(22),
                },
            })}
        >
            {label}
        </Typography>

        <TextField
            inputRef={inputRef}
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            error={formik.errors[field] && formik.touched[field]}
            fullWidth
            helperText={formik.touched[field] && formik.errors[field]}
            hiddenLabel
            margin="dense"
            multiline={multiline}
            name={field}
            rows={rows}
            type="text"
            variant="filled"
            InputProps={{
                endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
                sx: multiline && { padding: "0" }
                }}
            value={phoneNumber}
            // value={formik.values[field]}
            onChange={(e) => {
                setPhoneNumber(e.target.value)
                formik.setFieldError(e.target.name, undefined);
                formik.handleChange(e)
            }}
            sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                    marginTop: `${8 * scaleFactor / 16}vw`,
                    marginBottom: `${4 * scaleFactor / 16}vw`,
                    '& .MuiInputBase-root': {
                        borderTopLeftRadius: `${4 * scaleFactor / 16}vw`,
                        borderTopRightRadius: `${4 * scaleFactor / 16}vw`,
                        fontSize: `${16 * scaleFactor / 16}vw`,
                        lineHeight: `${23 * scaleFactor / 16}vw`,
                        '&:after, &:before': {
                            borderBottom: `${1 * scaleFactor / 16}vw solid rgba(255, 255, 255, 0.7)`
                        },
                        '& .MuiInputBase-input': {
                            padding: `${12 * scaleFactor / 16}vw`,
                            fontSize: `${14 * scaleFactor / 16}vw`,
                            lineHeight: `${22 * scaleFactor / 16}vw`,
                            height: `${22 * scaleFactor / 16}vw`,
                        },
                    },
                    '& p.MuiFormHelperText-root.Mui-error': {
                        fontSize: vw(14),
                        marginLeft: vw(14),
                        marginRight: vw(14),
                        marginTop: vw(3),
                        lineHeight: vw(26),
                    }
                }
            })}
        />
    </>
}
