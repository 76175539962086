import ApiDefault from "./ApiDefault";

const PATH = 'v1/calendars';

export default class Calendar extends ApiDefault {
  async create(data) {
    return this.post(PATH, data);
  }

  async update(data, id) {
    return this.patch(PATH + `/${id}`, data);
  }

  async all() {
    return this.get(PATH);
  }

  async delete(id) {
    return this.remove(PATH + '/' + id)
  }
}
