import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export function ApartmentFormik(apartment, handleSubmit) {
  const { t } = useTranslation(["translation", "form"]);
  const initialApartment = { ...apartment }
  if (apartment) {
    initialApartment.state = initialApartment.city?.state;
    // initialApartment.operationType = initialApartment.operationType.value;
    // initialApartment.propertyType = initialApartment.propertyType.value;
    initialApartment.currency = initialApartment.currency.value;
    initialApartment.owner = initialApartment.owner ? initialApartment.owner : "";
    initialApartment.houseNumber = initialApartment.houseNumber ? initialApartment.houseNumber : "";
    initialApartment.livingArea = initialApartment.livingArea ? initialApartment.livingArea : "";
    initialApartment.serviceInformation = initialApartment.serviceInformation ? initialApartment.serviceInformation : "";
    // initialApartment.phone = initialApartment.phone ? initialApartment.phone : "";
  }

  const validationSchema = Yup.object().shape({
    operationType: Yup.object().nullable().required(t('required_field')),
    propertyType: Yup.object().nullable().required(t('required_field')),
    state: Yup.object().nullable().required(t('required_field')),
    city: Yup.object().nullable().required(t('required_field')),
    // street: Yup.object().nullable().required(t('required_field')),
    street: Yup.object().nullable(),
    houseNumber: Yup.string(),
    price: Yup.number().required(t('required_field')).typeError(t('typeNumber')).positive(t('typeMoreThanZiro')),
    totalArea: Yup.number().required(t('required_field')).typeError(t('typeNumber')).positive(t('typeMoreThanZiro')),
    livingArea: Yup.number().typeError(t('typeNumber')).positive(t('typeMoreThanZiro')),
    // kitchenArea: Yup.number().typeError(t('typeNumber')).positive(t('typeMoreThanZiro')),
    kitchenArea: Yup.number().nullable(),
    // rooms: Yup.number().required(t('required_field')).typeError(t('typeNumber')).positive(t('typeMoreThanZiro')),
    // rooms: Yup.number().nullable(),
    floor: Yup.number().required(t('required_field')).typeError(t('typeNumber')),
    floorTotal: Yup.number().required(t('required_field')).typeError(t('typeNumber')),
    repair: Yup.object().nullable().required(t('required_field')),
    owner: Yup.object().nullable().required(t('required_field')),
    ownerName: Yup.string().nullable(),
    phone: Yup.string().nullable(),
  });

  return useFormik({
    initialValues: (apartment && initialApartment) || {
      operationType: null,
      propertyType: null,
      state: null,
      city: null,
      cityRegion: null,
      citySubregion: null,
      street: null,
      houseNumber: "",
      residentialComplex: null,
      currency: 0,
      price: "",
      totalArea: "",
      livingArea: "",
      kitchenArea: "",
      rooms: "",
      floor: "",
      floorTotal: "",
      repair: null,
      owner: null,
      ownerName: "",
      phone: null,
      description: "",
      serviceInformation: ""
    }, enableReinitialze: true, validationSchema: validationSchema, onSubmit: (values) => {
      const data = {
        currency: values.currency,
        description: values.description,
        floor: Number(values.floor),
        floorTotal: Number(values.floorTotal),
        houseNumber: values.houseNumber,
        kitchenArea: Number(values.kitchenArea),
        livingArea: Number(values.livingArea),
        operationType: values.operationType.value,
        owner: values.owner.value,
        ownerName: values.ownerName,
        phone: values.phone ? values.phone : null,
        price: Number(values.price),
        propertyType: values.propertyType.value,
        repair: values.repair.value,
        residentialComplexId: values.residentialComplex?.id,
        rooms: Number(values.rooms),
        serviceInformation: values.serviceInformation,
        street: values.street,

        state: values.state,
        city: values.city,
        cityRegion: values.cityRegion,
        citySubregion: values.citySubregion,


        totalArea: Number(values.totalArea)
      }

      if (apartment) {
        data.id = apartment.id;
      }

      handleSubmit(data);
    },
  });
}