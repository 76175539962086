import { iconButtonClasses, Typography } from "@mui/material";
import { DatePicker, datePickerToolbarClasses, LocalizationProvider, TimePicker, } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/ru';
import React from 'react';
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

export const TimePickerInput = ({ field, formik, label, placeholder, required = true }) => {

    const minTime = dayjs().hour(7).minute(0);
    const maxTime = dayjs().hour(22).minute(0);

    const roundToStep = (time) => {

        if (!time) {
            return null;
        }

        const roundedMinutes = Math.round(time.minute() / 15) * 15;

        return time.minute(roundedMinutes)
    };

    const commonProps = {
        value: dayjs(formik.values[field]),
        onChange: (value) => formik.setFieldValue(field, roundToStep(value), true),
        slotProps: {
            textField: {
                margin: "dense",
                name: field,
                placeholder: placeholder,
                type: "text",
                fullWidth: true,
                variant: "filled",
                error: false
            }
        }
    }

    return <>
        <Typography className={required && "label dataPicker_911"} variant="h4" sx={{ mt: 3.75 }}>
            {label}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ua'}>
            <StyledTimePicker ampm={false} minutesStep={15} minTime={minTime} maxTime={maxTime} shouldDisableTime={(time, view) => view === "minutes" && time.minute() % 15 !== 0} {...commonProps} />
        </LocalizationProvider>
    </>
}

const StyledTimePicker = styled(TimePicker)({
    [`& .${iconButtonClasses.root}`]: {
        backgroundColor: 'transparent',
        "& :hover": {
            backgroundColor: 'transparent',
        }
    },
});

const StyledDatePicker = styled(DatePicker)({
    [`& .${iconButtonClasses.root}`]: {
        backgroundColor: 'transparent',
        "& :hover": {
            backgroundColor: 'transparent',
        }
    },
    [`& .${datePickerToolbarClasses.root}`]: {
        [`& .${iconButtonClasses.root}`]: {
            backgroundColor: 'transparent',
            "& :hover": {
                backgroundColor: 'transparent',
            }
        },
    }
});
