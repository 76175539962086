import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { getAddress } from "helpers";
import React from "react";
import ApartmentInformation from "./ApartmentInformation";
import useResize from "../../../config/themes/responsive";

export default function ApartmentAccordion({ apartment, error, handleSubmit, index, refProp }) {

  const scaleFactor = useResize();
  const vw = (px) => `${(px * scaleFactor) / 16}vw`;
  const style = error ? { border: "1px solid red" } : {};

  return <Accordion
      key={index}
      sx={(theme) => ({
          border: '1px solid #00000020',
          marginBottom: '1px',
          [theme.breakpoints.up("md")]: {
              marginBottom: vw(1) + '!important',
              borderWidth: vw(1)
          },
      })}
  >
    <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content" id={apartment.id + "-header"}

        sx={(theme) => ({
            style,
            [theme.breakpoints.up("md")]: {
                minHeight: vw(48) + '!important',
                padding: `${vw(0)} ${vw(16)}`,
                '& .MuiAccordionSummary-contentGutters': {
                    margin: `${vw(12)} ${vw(0)}`,
                },
                '& .MuiSvgIcon-root': {
                    height: vw(24),
                    fontSize: vw(24),
                    width: vw(24),
                }
            },
        })}
    >
      <DragIndicatorIcon
          className="column-drag-handle"
          fontSize="small"
          sx={(theme) => ({
            color: "#141414",
            mr: 2,
            [theme.breakpoints.up("md")]: {
              width: vw(20),
              height: vw(20),
              marginRight: vw(16),
              fontSize: vw(20),
            },
          })}
      />
      <Typography
          sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                  height: vw(24),
                  fontSize: vw(16),
                  lineHeight: vw(24),
              },
          })}>{getAddress(apartment)}
      </Typography>
    </AccordionSummary>
    <AccordionDetails
        sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
                padding: `${vw(8)} ${vw(16)} ${vw(16)}`,
            },
        })}
    >
      <ApartmentInformation apartment={apartment} handleSubmit={handleSubmit} key={index + apartment.id} ref={refProp} />
    </AccordionDetails>
  </Accordion>;
}