import { configureStore } from '@reduxjs/toolkit';
import { agentReducer } from './agent.slice';
import { apartmentReducer } from './apartment.slice';
import { authReducer } from './auth.slice';
import { calendarReducer } from './calendar.slice';
import { clientReducer } from './client.slice';
import { departmentReducer } from './department.slice';
import { apartmentBoardReducer } from './apartmentBoard.slice';
import { messageReducer } from './message.slice';
import { notificationReducer } from './notification.slice';
import { subscriptionReducer } from './subscription.slice';
import { clientBoardReducer } from './clientBoard.slice';

export * from './apartment.slice';
export * from './auth.slice';
export * from './agent.slice';
export * from './calendar.slice';
export * from './client.slice';
export * from './department.slice';
export * from './apartmentBoard.slice';
export * from './message.slice';
export * from './notification.slice';
export * from './subscription.slice';
export * from './clientBoard.slice';

export const store = configureStore({
  reducer: {
    apartment: apartmentReducer,
    auth: authReducer,
    agent: agentReducer,
    calendar: calendarReducer,
    client: clientReducer,
    department: departmentReducer,
    apartmentBoard: apartmentBoardReducer,
    message: messageReducer,
    notification: notificationReducer,
    subscription: subscriptionReducer,
    clientBoard: clientBoardReducer,
  },
});