import ApiDefault from "./ApiDefault";

const PATH = 'v1/feeds';

export default class Feed extends ApiDefault {
  async approve(data) {
    return this.post(PATH + "/approve", data);
  }

  async update(data) {
    return this.post(PATH + "/update", data);
  }
}