import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { subscriptionApi } from "helpers";

const name = "subscription";
const initialState = {me: {}, departmnes: []};

const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, reducers, extraReducers});

export const subscriptionActions = { ...slice.actions, ...extraActions };
export const subscriptionReducer = slice.reducer;

function createReducers() {
  return {
    changeUser, changeDepartment, getList
  };

  function changeUser(state, { payload }) {

    return state;
  }

  function changeDepartment(state, { payload }) {

    return state;
  }

  function getList(state, { payload }) {

    return state;
  }
}

function createExtraActions() {
    return {
        changeUser: changeUser(),
        changeDepartment: changeDepartment(),
        getList: getList()
    };

    function changeUser() {
        return createAsyncThunk(`${name}/changeUser`, async (data) => await subscriptionApi.changeUser(data));
    }

    function changeDepartment() {
        return createAsyncThunk(`${name}/changeDepartment`, async (data) => await subscriptionApi.changeDepartment(data));
    }

    function getList() {
        return createAsyncThunk(`${name}/getList`, async () => await subscriptionApi.getList());
    }
}

function createExtraReducers() {
    return {
      ...changeUser(), ...changeDepartment(), ...getList()
    };
  
    function changeUser() {
        const { fulfilled } = extraActions.changeUser;
        return {
          [fulfilled]: (state, action) => {
            return;
          },
        };
    }

    function changeDepartment() {
        const { fulfilled } = extraActions.changeDepartment;
        return {
          [fulfilled]: (state, action) => {
            return;
          },
        };
    }

    function getList() {
        const { fulfilled } = extraActions.getList;
        return {
          [fulfilled]: (state, action) => {
            return;
          },
        };
    }
}
