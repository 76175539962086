import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {Button, FormControlLabel, Typography, Menu, MenuItem, Checkbox, formControlLabelClasses} from "@mui/material";
import {NestedMenuItem} from 'mui-nested-menu';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {departmentActions} from "store";
import {useTheme} from "@mui/material/styles";
import useResize from "../../config/themes/responsive";

export function DepartmentAgentSelectorWithCheckbox({categories, setCategories}) {
    const {t} = useTranslation(["translation", "form"]);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const user = useSelector(store => store.auth.user);
    const {department} = useSelector((store) => store);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const scaleFactor = useResize();

    const vw = (px) => `${(px * scaleFactor) / 16}vw`;
    useEffect(() => {
        user && dispatch(departmentActions.all());
    }, [user, dispatch]);

    useMemo(() => {
        const updatedCategories = department.map(department => {
            const result = {id: department.id, name: department.title};
            result.subcategories = [];

            if (department.head) {
                result.subcategories.push({
                    id: department.head.id,
                    name: `${department.head.lastName} ${department.head.firstName} ${department.head.middleName}`,
                    checked: true
                });
            }

            department.agents.forEach(agent => {
                if (agent.status !== 0) {
                    result.subcategories.push({
                        id: agent.id,
                        name: `${agent.lastName} ${agent.firstName} ${agent.middleName}`,
                        checked: true
                    });
                }
            });
            return result;
        });

        if (user.isDirector) {
            updatedCategories.unshift({
                id: user.id,
                name: t('director'),
                subcategories: [{
                    id: user.id,
                    email: user.email,
                    name: user.fullName,
                    checked: true
                }]
            });
        }

        setCategories(updatedCategories);
    }, [department, user]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const total = categories.reduce((total, category) => total + category.subcategories.length, 0);
    const checked = categories.reduce((total, category) => total + category.subcategories.filter(subcategory => subcategory.checked).length, 0);
    const allChecked = total === checked;

    return (
        <>
            <Typography
                variant="text"
                sx={(theme) => ({
                    fontWeight: 500,
                    fontSize: {
                        xs: "13px",
                        [theme.breakpoints.up("md")]: `${(16 * scaleFactor) / 16}vw`
                    },
                    [theme.breakpoints.up("md")]: {
                        fontSize: vw(16),
                        lineHeight: vw(24),
                    },
                    color: "newGrey.dark"
                })}>{t('agent')}: </Typography>
            <Button
                color="text"
                disabled={!user.isCrmUser}
                endIcon={open ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                onClick={handleClick}
                variant="text"
                sx={(theme) => ({
                    fontWeight: 500,
                    fontSize: {xs: "13px", [theme.breakpoints.up("md")]: `${(16 * scaleFactor) / 16}vw`},
                    color: "dark",
                    [theme.breakpoints.up("md")]: {
                        fontSize: vw(16),
                        padding: `${vw(6)} ${vw(8)}`,
                        lineHeight: vw(24),
                        borderRadius: vw(4),
                        minWidth: vw(64),
                        '& .MuiButton-endIcon': {
                            marginRight: vw(-4),
                            marginLeft: vw(8),
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: vw(20),
                            width: vw(20),
                            height: vw(20),
                        },
                    },
                })}
            >
                {allChecked ? t('all') : `${checked} ${t('c')} ${total}`}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        '& .MuiMenuItem-gutters': {
                            padding: `${vw(6)} ${vw(4)}`,
                        },
                        '& .MuiMenuItem-gutters .MuiSvgIcon-root': {
                            fontSize: vw(24),
                            width: vw(24),
                            height: vw(24),
                            top: vw(-1)
                        },
                        '& .MuiTypography-body1': {
                            padding: `${vw(0)} ${vw(8)}`,
                            fontSize: vw(16),
                            lineHeight: vw(28),
                        }
                    },
                })}>
                {categories.map((category) => (
                    !!category.subcategories.length && (
                        <Submenu key={category.id} category={category} open={Boolean(anchorEl)}/>
                    )
                ))}
            </Menu>
        </>
    );
}

const Submenu = ({category, open}) => {
    const scaleFactor = useResize();
    const vw = (px) => `${(px * scaleFactor) / 16}vw`;

    return (
        <NestedMenuItem parentMenuOpen={open} rightIcon={<ArrowRightIcon/>} label={category.name}>
            {category.subcategories.map((subcategory) => (
                <MenuItem
                    key={subcategory.id}
                    sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            padding: `${vw(6)} ${vw(16)}`,
                            '& .MuiFormControlLabel-labelPlacementEnd': {
                                marginLeft: vw(-11),
                                marginRight: vw(16),
                            },
                            '& .MuiCheckbox-root': {
                               padding: vw(9),
                            },
                            '& .MuiFormControlLabel-label': {
                                fontSize: vw(14) + "!important",
                                lineHeight: vw(21)
                            },
                            '& .MuiSvgIcon-root': {
                                fontSize: vw(24),
                                width: vw(24),
                                height: vw(24),
                                top: vw(-1)
                            },
                        },
                    })}>
                    <FormControlLabel control={<Checkbox checked={subcategory.checked}/>} label={subcategory.name}/>
                </MenuItem>
            ))}
        </NestedMenuItem>
    );
};