import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Button,
  Dialog,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  formControlLabelClasses
} from "@mui/material";
import { CloseDialog, SubmitResetGroup } from "components/index";
import { apartmentApi, presentationApi } from "helpers";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ApartmentAccordion from "./ApartmentAccordion";
import SendPresentationDialog from "./SendPresentationDialog";
import { apartmentActions, apartmentBoardActions, messageActions } from "store";
import { useDispatch, useSelector } from "react-redux";
import { Container, Draggable } from 'react-smooth-dnd';
import { getLinkForMessenger } from "helpers";
import { useCustomMediaQueries } from "helpers";
import LoadingButton from "@mui/lab/LoadingButton";
import useResize from "../../../config/themes/responsive";


export function PresentationDialog({ apartments, clientId, agent, handleClose, open }) {
  const { t } = useTranslation(["translation", "form"]);
  const [apartmentsData, setApartmentsData] = useState([]);
  const [errors, setErrors] = useState([])
  const [formData, setFormData] = useState([])
  const [presentationId, setPresentationId] = useState()
  const [isPreview, setIsPreview] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openSend, setOpenSend] = useState(false)
  const formsRef = useRef([]);
  const mainFormRef = useRef(null);
  const dispatch = useDispatch();
  formsRef.current = formsRef.current.slice(0, apartmentsData.length);
  const scaleFactor = useResize();

  const vw = (px) => `${(px * scaleFactor) / 16}vw`;
  const [value, setValue] = useState('telegram');
  const { isMobile } = useCustomMediaQueries();
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  
  const handleOpenPagePreview = (apartmentsDataList) => {

    window.open("/presentation/current/preview", "_blank");
  }

  useEffect(() => {
    apartmentApi.getApartment(apartments).then(({ result }) => {
      setApartmentsData(result);
      localStorage.setItem('currentPreview', JSON.stringify(result))
      localStorage.setItem('currentAgent', JSON.stringify(agent))
    })
  }, []); // apartments

  useEffect(() => {
    if (!formData.length) {
      return;
    }

    setErrors(apartmentsData.filter(apartment =>
      formData.filter(formApartment => formApartment.id === apartment.id).length === 0
    ).map(apartment => apartment.id));

    if (formData.length === apartmentsData.length) {

      if (document.activeElement.type === 'submit') {

        // apartmentsData.map(apartment => formData.find(form => form.id === apartment.id));

          const data = JSON.parse(localStorage.getItem('currentPreview'));
          
          setLoading(true);
          presentationApi.create({apartments: data, clientId, presentationId}).then(({ id }) => {
            if (clientId) {
              dispatch(apartmentBoardActions.clientAll(clientId));
            } else {
              dispatch(apartmentBoardActions.list());
            }
            dispatch(apartmentBoardActions.list());
            setPresentationId(id);
            setLoading(false);
            // if (id && isPreview) {
            //   window.open && window.open('/presentation/' + id, '_blank').focus();
            // }
            // if (id && !isPreview) {
            //   setOpenSend(true);
            // }
            const url = (window.location.protocol === 'http:' ? 'http://' : 'https://') + window.location.host + "/presentation/" + id;
            const link = getLinkForMessenger(value, url, isMobile);
            link && window.open && window.open(link + url, '_blank').focus();
            handleClose();
          });

      } else {
        return;
      }
    }
  }, [formData])

  const handleSubmit = (data) => {
    setFormData((prevFormData) => {
      const filtered = prevFormData.filter(apartment => apartment.id !== data.id);
      return [...filtered, data]
    })
  };

  const handlePreview = () => {
    // setIsPreview(true);
    mainFormRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  }

  const handleDrop = (arr, dragResult) => {

    const { removedIndex, addedIndex, payload } = dragResult;

    if (removedIndex === null && addedIndex === null) {return arr};
  
    const result = [...arr];
    let itemToAdd = payload;
  
    if (removedIndex !== null) {

      itemToAdd = result.splice(removedIndex, 1)[0];
    }
  
    if (addedIndex !== null) {

      result.splice(addedIndex, 0, itemToAdd);
    }

    localStorage.setItem('currentPreview', JSON.stringify(result))

    setApartmentsData(result)
  };

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth={true}
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            '& .MuiDialog-paper': {
              margin: vw(32),
              borderRadius: vw(4),
              width: `calc( 100% - ${vw(64)})`,
              maxHeight: `calc( 100% - ${vw(64)})`,
              maxWidth: vw(1200),
            }
          },
        })}>
      <CloseDialog handleClose={handleClose} />
      <Box className="isDraggingFalse"
           sx={(theme) => ({
             margin: "46px 148px 100px 148px",
             alignItems: "center", position: "relative",
             [theme.breakpoints.up("md")]: {
               margin: `${vw(46)} ${vw(148)} ${vw(100)}`
             },
           })}
      >
        <Typography
            variant="h1"
            sx={(theme) => ({
              fontWeight: "bold",
              mb: 2.5,
              mt: 7.5,
              [theme.breakpoints.up("md")]: {
                fontSize: vw(36),
                lineHeight: vw(44),
                marginTop: vw(60),
                marginBottom: vw(20),
              },
            })}
        >
          {t("sendPresentation")}
        </Typography>
        {
          apartments.length > 1 && <Typography variant="h5" sx={{ mt: 2.5, mb: 6 }}>{t("choosedObjects")}</Typography>
        }
          <Box
            component="form"
            className='form-send-presentation'
            ref={mainFormRef}
            onSubmit={(event) => {event.preventDefault(); formsRef.current.forEach(form => form.prepareData())}}
            sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                    '& .smooth-dnd-container': {
                        minWidth: vw(30),
                        minHeight: vw(30),
                    }
                },
            })}
        >
          <Container
              groupName="presentationApartments"
              dragHandleSelector=".column-drag-handle"
              onDrop={(event) => {handleDrop(apartmentsData, event)}}
          >
            {
              apartmentsData.map((apartment, index) => (
                <Draggable className='isDraggingFalse' key={index}>
                  <ApartmentAccordion key={index} apartment={apartment} error={errors.includes(apartment.id)} handleSubmit={handleSubmit} index={index} refProp={element => formsRef.current[index] = element} />
                </Draggable>
              ))
            }
          </Container>
          <div>
            <LoadingButton
                type="button" onClick={() => {setOpenSend(true)}}
                variant="contained"
                loading={loading}
                disabled={false}
                endIcon={<></>}
                color="primary"
                sx={(theme) => ({
                  display: 'block',
                  margin: '0px auto',
                  marginTop:"50px",
                  width: '100%',
                  maxWidth: '400px',
                  [theme.breakpoints.up("md")]: {
                    marginTop: vw(50),
                    maxWidth: vw(400),
                    padding: `${vw(6)} ${vw(16)}`,
                    minWidth: vw(64),
                    borderRadius: vw(4),
                    fontSize: vw(14),
                    lineHeight: vw(24),
                  },
                })}
            >
              {t('send')}
            </LoadingButton>
            <Button
                onClick={handleClose}
                disabled={loading}
                type="reset"
                sx={(theme) => ({
                    display: 'block',
                    margin: '0px auto',
                    mt: 2,
                    width: '100%',
                    maxWidth: '400px',
                    color: (theme) => theme.palette.dark,
                    [theme.breakpoints.up("md")]: {
                        padding: `${vw(6)} ${vw(8)}`,
                        minWidth: vw(64),
                        borderRadius: vw(4),
                        fontSize: vw(14),
                        lineHeight: vw(24),
                        maxWidth: vw(400),
                    },
                })}
            >
              {null !== null ? null : t('cancelTitle')}
            </Button>
          </div>
          <Dialog
              open={openSend}
              onClose={handleClose}
              maxWidth="lg"
              sx={(theme) => ({
                  [theme.breakpoints.up("md")]: {
                      '& .MuiDialog-paper': {
                          margin: vw(32),
                          borderRadius: vw(4),
                          maxHeight: `calc( 100% - ${vw(64)})`,
                          maxWidth: vw(1200),
                      }
                  },
              })}
          >
            <CloseDialog handleClose={handleClose} />
            <Box
                sx={(theme) => ({
                    margin: "46px 148px 100px 148px",
                    alignItems: "center",
                    [theme.breakpoints.up("md")]: {
                        margin: `${vw(46)} ${vw(148)} ${vw(100)}`
                    },
                })}
            >
              <Typography
                  variant="h1"
                  sx={(theme) => ({
                      fontWeight: "bold",
                      mb: 2.5,
                      mt: 7.5,
                      [theme.breakpoints.up("md")]: {
                          fontSize: vw(36),
                          lineHeight: vw(44),
                          marginTop: vw(60),
                          marginBottom: vw(20),
                      },
                  })}
              >
                {t("sendPresentation")}
              </Typography>
              <Typography
                  variant="h5"
                  sx={(theme) => ({
                      fontWeight: "bold",
                      mb: 2.5,
                      mt: 6,
                      [theme.breakpoints.up("md")]: {
                          fontSize: vw(14),
                          lineHeight: vw(22),
                          marginTop: vw(48),
                          marginBottom: vw(20),
                      },
                  })}
              >
                {t("sendPresentationType")}
              </Typography>
              <Box>
                <FormControl>
                  <RadioGroup value={value} onChange={handleChange}
                    sx={(theme) => ({
                      [theme.breakpoints.up("md")]: {
                          '& .MuiFormControlLabel-root': {
                              marginLeft: vw(-11),
                              marginRight: vw(16),
                          },
                          '& .MuiRadio-root': {
                              padding: vw(9),
                          },
                          '& .MuiSvgIcon-root': {
                              width: vw(24),
                              height: vw(24),
                              fontSize: vw(24),
                          },
                          '& .MuiFormControlLabel-label': {
                              fontSize: vw(16),
                              lineHeight: vw(24),
                          }
                      },
                  })}>
                    <FormControlLabel value="telegram" control={<Radio />} label="Telegram"/>
                    <FormControlLabel value="viber" control={<Radio />} label="Viber" />
                    <FormControlLabel value="whatsapp" control={<Radio />} label="WhatsApp" />
                    <FormControlLabel value="link" control={<Radio />} label={t("copyLink")} />
                  </RadioGroup>
                </FormControl>
              </Box>
              <div>
                <LoadingButton
                    endIcon={<></>}
                    color="primary"
                    disabled={false}
                    loading={false}
                    onClick={(event) => {event.preventDefault(); formsRef.current.forEach(form => form.prepareData())}}
                    sx={(theme) => ({
                      display: 'block',
                      margin: '0px auto',
                      marginTop:"50px",
                      width: '100%',
                      maxWidth: '400px',
                      [theme.breakpoints.up("md")]: {
                        marginTop: vw(50),
                        maxWidth: vw(400),
                        padding: `${vw(6)} ${vw(16)}`,
                        minWidth: vw(64),
                        borderRadius: vw(4),
                        fontSize: vw(14),
                        lineHeight: vw(24),
                      },
                    })}
                    type="submit"
                    variant="contained">
                  {t('send')}
                </LoadingButton>
                <Button
                    disabled={false}
                    type="reset"
                    onClick={() => setOpenSend(false)}
                    sx={(theme) => ({
                      display: 'block',
                      margin: '0px auto',
                      mt: 2, width: '100%',
                      maxWidth: '400px',
                      color: (theme) => theme.palette.dark,
                      [theme.breakpoints.up("md")]: {
                        padding: `${vw(6)} ${vw(8)}`,
                        minWidth: vw(64),
                        borderRadius: vw(4),
                        fontSize: vw(14),
                        lineHeight: vw(24),
                      },
                    })}
                >
                  {null !== null ? null : t('cancelTitle')}
                </Button>
              </div>
            </Box>
          </Dialog>
        </Box>
        <Box sx={{ bottom: 0, position: "absolute", right: 0 }}>
          <Button
              color="text"
              disabled={loading}
              onClick={() => {handleOpenPagePreview(apartmentsData)}}
              variant="text"
              startIcon={<VisibilityOutlinedIcon />}
              sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                  padding: `${vw(6)} ${vw(8)}`,
                  minWidth: vw(64),
                  borderRadius: vw(4),
                  fontSize: vw(14),
                  lineHeight: vw(29),
                  '& .MuiButton-startIcon': {
                    marginLeft: vw(-4),
                    marginRight: vw(8),
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: vw(20),
                    width: vw(20),
                    height: vw(20),
                    lineHeight: vw(34),
                  }
                },
              })}
          >
            {t("showPreview")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
