import { Box, Dialog, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { SubmitResetGroup } from "../buttons";
import { CloseDialog } from "./CloseDialog";
import useResize from "../../config/themes/responsive";

export function ConfirmDialog({
    addLabel = null,
    cancelLabel = null,
    handleClose,
    loading,
    open,
    onSubmit,
    onCancel,
    subTitle,
    title
  }) {
  const { t } = useTranslation(["translation", "form"]);
  const scaleFactor = useResize();

  const vw = (px) => `${(px * scaleFactor) / 16}vw`;

  return (<Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
      sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
              '& .MuiDialog-paper': {
                  maxWidth: vw(810),
                  borderRadius: vw(4),
                  margin: vw(32),
                  width: `calc( 100% - ${vw(64)})`,
                  maxHeight: `calc( 100% - ${vw(64)})`,
              },
          },
      })}
  >
    <CloseDialog handleClose={handleClose} />
    <Box
        sx={(theme) => ({
            margin: "64px 148px 44px 148px",
            alignItems: "center",
            textAlign: "center",
            [theme.breakpoints.up("md")]: {
                margin: `${vw(64)} ${vw(148)} ${vw(44)} ${vw(148)}`
            },
        })}
    >
      <Typography
          variant="h2"
          sx={(theme) => ({
              fontWeight: "bold",
              mb: 2.25 ,
              [theme.breakpoints.up("md")]: {
                  fontSize: vw(28),
                  marginBottom: vw(18),
              },
          })}
      >
        {title}
      </Typography>
      <Typography
          variant="h4"
          sx={(theme) => ({
              mt: 2.5,
              mb: 6,
              color:"newGrey.dark",
              [theme.breakpoints.up("md")]: {
                  fontSize: vw(14),
                  marginBottom: vw(48),
                  marginTop: vw(20),
                  lineHeight: vw(22)
              },
          })}
      >
        {subTitle}
      </Typography>
      <Box
          component="img"
          src="/images/dialogs/question.svg"
          alt="question"
          sx={(theme) => ({
            height: "160px",
            width: "240px",
            borderBottom: "1px solid #141414",
            marginBottom:"36px",
            [theme.breakpoints.up("md")]: {
                height: vw(160),
                width: vw(240),
                borderWidth: vw(1),
                marginBottom: vw(36),
            },
          })}
      />
      <form onSubmit={(e) => {
        e.preventDefault();
        onSubmit()
      }}>
        <SubmitResetGroup
          addLabel={addLabel !== null ? addLabel : t('confirm_button')}
          cancelLabel={cancelLabel !== null ? cancelLabel : t('cancelTitle')}
          handleClose={() => {
          onCancel ? onCancel() : handleClose()
        }} loading={loading} />
      </form>
    </Box>
  </Dialog>);
}
