import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button } from "./Button";
import React from 'react';
import useResize from "../../config/themes/responsive";

export const AddButton = ({ onClick, disabled }) => {
  const scaleFactor = useResize();
  return (
    <Button className="add-button" onClick={onClick} disabled={disabled}>
      <AddOutlinedIcon sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
              height: `${20 * scaleFactor / 16}vw`,
              width: `${20 * scaleFactor / 16}vw`,
          },
          [theme.breakpoints.up("xl")]: {
              height: `${20 * scaleFactor / 16}vw`,
              width: `${20 * scaleFactor / 16}vw`,
          },
      })} fontSize={"inherit"} />
    </Button>
  );
};
