import { Avatar as MuiAvatar } from "@mui/material";
import { ReactComponent as AvatarIcon } from 'assets/avatar.svg';
import {useTheme} from "@mui/material/styles";
import useResize from "../../config/themes/responsive";

export const Avatar = ({ src, ...props }) => {
    const theme = useTheme();
    const scaleFactor = useResize();

    return src ? (
        <MuiAvatar
            src={src}
            {...props}
            sx={{
                [theme.breakpoints.up("md")]: {
                    width: `${40 * scaleFactor / 16}vw`,
                    height: `${40 * scaleFactor / 16}vw`,
                    marginBottom: `${8 * scaleFactor / 16}vw`,
                    marginRight: `${24 * scaleFactor / 16}vw !important`,
                },
                ...props.sx,
            }}
        />
    ) : (
        <MuiAvatar
            {...props}
            sx={{
                [theme.breakpoints.up("md")]: {
                    width: `${40 * scaleFactor / 16}vw`,
                    height: `${40 * scaleFactor / 16}vw`,
                    marginBottom: `${8 * scaleFactor / 16}vw`,
                    marginRight: `${24 * scaleFactor / 16}vw`,
                },
                ...props.sx,
            }}
        >
            <AvatarIcon style={{ height: "100%", width: "100%" }} />
        </MuiAvatar>
    );
};
