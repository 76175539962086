import { ListSubheader, MenuItem, Select, Button } from "@mui/material";
import React from 'react';
import { useTranslation } from "react-i18next";
import useResize from "../../config/themes/responsive";

export const CustomLabeledSelect = ({ name, onChange, options, value, openSelect, setOpenSelect, focusedRowIndex, tableRefs }) => {

  const { t } = useTranslation(["translation", "form"]);
  const scaleFactor = useResize();

  const vw = (px) => `${(px * scaleFactor) / 16}vw`;

  return (
    <Select 
        fullWidth
        sx={(theme) => ({
            fontSize: '14px',
            [theme.breakpoints.up("md")]: {
                fontSize: vw(14),
                borderRadius: vw(4),
                lineHeight: vw(24),
                '& .MuiSelect-select':{
                    padding: `${vw(8.5)} ${vw(32)} ${vw(8.5)} ${vw(14)} !important`,
                    borderRadius: vw(4),
                    minHeight: vw(24),
                },
                '& svg.MuiSvgIcon-root': {
                    width: vw(20),
                    height: vw(20),
                    fontSize: vw(20),
                },
                '& svg.MuiSelect-icon': {
                    top: `calc(50% - ${vw(12)})`,
                    right: vw(7),
                    width: vw(24),
                    height: vw(24),
                    fontSize: vw(24),
                }
            },
        })}
        size="small"
        name={name}
        // open={openSelect}
        onOpen={() => {
          setOpenSelect(true);
          setTimeout(() => {
              // document.activeElement.blur();
          }, 0);
        }}
        onClose={() => {
          setTimeout(() => {
            document.activeElement.blur();
            if (tableRefs.current[focusedRowIndex]) {
              tableRefs.current[focusedRowIndex].focus();
            }
          }, 0);
        }}
        value={value}
        onChange={onChange}

        // MenuProps={{
        //   onClick: e => {
        //     // e.preventDefault();

        //     document.activeElement.blur();
        //     if (tableRefs.current[focusedRowIndex]) {
        //       tableRefs.current[focusedRowIndex].focus();
        //     }

        //     }
        //   }}
    >
        {
            options.map((option, key) => option.value !== undefined
            ? <MenuItem
                        key={key}
                        value={option.value}
                        sx={(theme) => ({
                            [theme.breakpoints.up("md")]: {
                                padding: `${vw(6)} ${vw(16)}`,
                                marginTop: vw(0) + '!important',
                                '& .MuiTypography-root': {
                                    fontSize: vw(16),
                                    lineHeight: vw(24),
                                }
                            },
                        })}
                    >{option.name}</MenuItem>
            : <ListSubheader key={key} sx={{ backgroundColor: "grey.main" }}>{option.name}</ListSubheader>
            )
        }

        {/* <Button
            size="small"
            variant="contained"
            sx={{
                display: 'block',
                margin: '10px auto',
                color: (theme) => theme.palette.main,
                boxShadow: 'none',
                p: "3px 8px",
                '&:hover': {
                    boxShadow: 'none',
                    color: "white"
                },
            }}
            onClick={() => {
                setOpenSelect(false);
                setTimeout(() => {
                    document.activeElement.blur();
                    if (tableRefs.current[focusedRowIndex]) {
                        tableRefs.current[focusedRowIndex].focus();
                    }
                }, 0);
            }}
        >
            {t('oksave')}
        </Button> */}
    </Select>
  );
}
