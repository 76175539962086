import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from 'react';
import useResize from "../../config/themes/responsive";

const StyledButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  [`&:hover`]: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
  },
  [`&:disabled`]: {
    backgroundColor: theme.palette.grey.main,
  },
}));

export const Button = ({ children, disabled, onClick, className = "" }) => {
  const scaleFactor = useResize();
  return (
    <StyledButton
      className={className}
      disabled={disabled}
      onClick={onClick}
      sx={(theme) => ({
        fontSize: { xs: "16px", xl: "20px" },
        height: { xs: "32px", xl: "40px" },
        width: { xs: "32px", xl: "40px" },
        [theme.breakpoints.up("md")]: {
          fontSize: `${20 * scaleFactor / 16}vw`,
          height: `${32 * scaleFactor / 16}vw`,
          width: `${32 * scaleFactor / 16}vw`,
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: `${20 * scaleFactor / 16}vw`,
          height: `${32 * scaleFactor / 16}vw`,
          width: `${32 * scaleFactor / 16}vw`,
        },
      })}
    >
      {children}
    </StyledButton>
  );
};
