import { FormControl, FormHelperText, ListSubheader, MenuItem, Select, Typography } from "@mui/material";
import React from 'react';
import useResize from "../../config/themes/responsive";

export const LabeledSelect = ({ disabled, field, formik, label, options, placeholder, required = true }) => {
  const scaleFactor = useResize();

  const vw = (px) => `${(px * scaleFactor) / 16}vw`;
  const handleChangeValuePreview = (event, newValue) => {

    if (event) {
      const apartmentsList = JSON.parse(localStorage.getItem('currentPreview'));

      if (apartmentsList) {

        const currentApartment = apartmentsList.map(apartment => {
          if (apartment.id === formik.values.id) {
            return {...apartment, [field]: newValue}
          }
          return apartment;
        })
  
        localStorage.setItem('currentPreview', JSON.stringify(currentApartment))
      }
    }
  }

  return <FormControl fullWidth sx={(theme) => ({
    [theme.breakpoints.up("md")]: {
      '& .MuiInputBase-root.MuiFilledInput-root': {
        // padding: '0',
      },
    },
  })}>
    <Typography
        className={required && "label 555"}
        variant="h4"
        mt={{ xs: 1.5, md: 3.75 }}
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            marginTop: vw(30),
            fontSize: vw(14),
            lineHeight: vw(22),
          },
        })}
    >
      {label}
    </Typography>
    <Select
      error={formik.errors[field] && formik.touched[field]}
      fullWidth
      disabled={disabled}
      name={field}
      // onChange={formik.handleChange}
      onChange={(event) => {
        formik.handleChange(event)
        handleChangeValuePreview(event, event.target.value)
      }}
      placeholder={placeholder}
      sx={(theme) => ({
        mb: 0.5, mt: 1, '& .MuiSelect-select .notranslate::after': placeholder ? {
          content: `"${placeholder}"`, opacity: 0.42,
        } : {},
        [theme.breakpoints.up("md")]: {
          mb: `${0.5 * 8 * scaleFactor / 16}vw`,
          mt: `${8 * scaleFactor / 16}vw`,
          fontSize: vw(16),
          lineHeight: vw(22),

          '& .MuiSvgIcon-root': {
            width: `${16 * scaleFactor / 16}vw`,
            height: `${16 * scaleFactor / 16}vw`,
            right: `${7 * scaleFactor / 16}vw`,
            top: `calc(50% - ${8 * scaleFactor / 16}vw)`,
            fontSize: `${24 * scaleFactor / 16}vw`,
          },
          '& .MuiSelect-select': {
            fontSize: `${14 * scaleFactor / 16}vw`,
            lineHeight: `${22 * scaleFactor / 16}vw`,
            padding: `${12 * scaleFactor / 16}vw ${32 * scaleFactor / 16}vw ${12 * scaleFactor / 16}vw ${12 * scaleFactor / 16}vw !important`,
          },
          '& .MuiSelect-iconFilled': {
            width: `${24 * scaleFactor / 16}vw`,
            height: `${24 * scaleFactor / 16}vw`,
            fontSize: `${44 * scaleFactor / 16}vw`,
            right: `${7 * scaleFactor / 16}vw`,
            top: `calc(50% - ${12 * scaleFactor / 16}vw)`,
          }
        },
      })}
      value={formik.values[field] ? formik.values[field] : options[0]?.value}
      variant="filled"
    >
      {options.map((option, key) => option.value !== undefined ? <MenuItem
          key={key}
          value={option.value}
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              padding: `${vw(6)} ${vw(16)}`,
              marginTop: vw(0) + '!important',
            },
          })}
      >{option.name}</MenuItem> : <ListSubheader key={key} sx={{ backgroundColor: "grey.main" }}>{option.name}</ListSubheader>)}
    </Select>
    {formik.touched[field] && formik.errors[field] && <FormHelperText error>{formik.errors[field]}</FormHelperText>}
  </FormControl>
}
