import Agent from "./Agent";
import Apartment from "./Apartment";
import Calendar from "./Calendar";
import Client from "./Client";
import Deal from "./Deal";
import Department from "./Department";
import Feed from "./Feed";
import Location from "./Location";
import Payment from "./Payment";
import Presentation from "./Presentation";
import Report from "./Report"
import Tariff from "./Tariff"
import User from "./User";
import ApartmentBoard from "./ApartmentBoard"
import Notification from "./Notification"
import Agency from "./Agency"
import Subscription from "./Subscription"
import ClientBoard from "./ClientBoard"
import Comment from "./Comment"
import Enum from "./Enum"

export * from "./database"
export * from "./m2"
export const agentApi = new Agent();
export const apartmentApi = new Apartment();
export const calendarApi = new Calendar();
export const clientApi = new Client();
export const dealApi = new Deal();
export const departmentApi = new Department();
export const feedApi = new Feed();
export const locationApi = new Location();
export const paymentApi = new Payment();
export const presentationApi = new Presentation();
export const reportApi = new Report();
export const tariffApi = new Tariff();
export const userApi = new User();
export const apartmentBoardApi = new ApartmentBoard();
export const notificationApi = new Notification();
export const agencyApi = new Agency();
export const subscriptionApi = new Subscription();
export const clientBoardApi = new ClientBoard();
export const commentApi = new Comment();
export const enumApi = new Enum();
