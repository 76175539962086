import { useState, useEffect } from "react";

const useResize = () => {
    const [scaleFactor, setScaleFactor] = useState(1);

    useEffect(() => {
        const updateScale = () => {
            if (window.innerWidth >= 1120) {
                const newScale = 1;
                setScaleFactor(newScale);
            } else {
                setScaleFactor(1);
            }
        };

        window.addEventListener("resize", updateScale);
        updateScale();

        return () => window.removeEventListener("resize", updateScale);
    }, []);

    return scaleFactor;
};

export default useResize;
