import ApiDefault from "./ApiDefault";

const PATH = 'v1/notifications';

export default class Notification extends ApiDefault {

    async create(data) {
        return this.post(PATH, data);
    }

    async update(data, id) {
        return this.patch(PATH + `/${id}`, data);
    }

    async updateAll(data) {

        return this.patch(PATH, data);
    }

    async getOne(id) {
        return this.get(PATH + `/${id}`);
    }

    async getList() {
        return this.get(PATH);
    }

    async delete(id) {
        return this.delete(PATH + `/${id}`);
    }
}
