import { Box, Container } from "@mui/material";
import Menu from "../modules/main/menu/Menu";
import React from 'react';
import useResize from "../config/themes/responsive";

export function MainContainer({ children, paddingTop = 0 }) {
  const scaleFactor = useResize();
  return <Container style={{minHeight: "100%", display: "flex", padding: 0, maxWidth: "100%" }}>
    <Box flexDirection="row" className="main" sx={{ width: "100%", minHeight: "100%" }} >
      <Box display="flex" flexDirection="row" sx={(theme) => ({
        height: "calc(100% - 64px)",
        [theme.breakpoints.up("md")]: {
          height: `calc(100% - ${(80 * scaleFactor) / 16}vw)`,
        },
      })}>
        <Box sx={(theme) => ({
          width: "64px",
          [theme.breakpoints.up("md")]: {
            width: `${(64 * scaleFactor) / 16}vw`,
          },
        })}>
          <Menu />
        </Box>
        <Box sx={(theme) => ({
          width: "calc(100% - 64px)",
          [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${(80 * scaleFactor) / 16}vw)`,
          },
        })}>
          {children}
        </Box>
      </Box>
    </Box>
  </Container>
}
