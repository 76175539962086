import { Box, Dialog, Typography } from "@mui/material";
import * as React from "react";
import { SubmitResetGroup } from "../buttons";
import { CloseDialog } from "./CloseDialog";
import { useTranslation } from "react-i18next";
import useResize from "../../config/themes/responsive";

export function DeleteDialog({
    addLabel = null,
    cancelLabel = null,
    handleClose,
    loading,
    open,
    onSubmit,
    onCancel,
    subTitle,
    title
  }) {
  const { t } = useTranslation(["translation", "form"]);
  const scaleFactor = useResize();

  return (<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true} sx={(theme) => ({
      [theme.breakpoints.up("md")]: {
          '& .MuiPaper-root': {
              maxWidth: `${810 * scaleFactor / 16}vw`,
              borderRadius: `${4 * scaleFactor / 16}vw`,
              maxHeight: `calc(100% - ${64 * scaleFactor / 16}vw)`,
              width: `calc(100% - ${64 * scaleFactor / 16}vw)`,
              margin: `${32 * scaleFactor / 16}vw`,
          }
      }
  })}>
    <CloseDialog handleClose={handleClose} />
    <Box sx={(theme) => ({
             margin: "46px 148px 44px 148px", alignItems: "center", textAlign: "center",
                 [theme.breakpoints.up("md")]: {
                     margin: `${46 * scaleFactor / 16}vw ${148 * scaleFactor / 16}vw ${44 * scaleFactor / 16}vw ${148 * scaleFactor / 16}vw`,
             }
         })}
    >
    <Typography variant="h2" sx={(theme) => ({
        fontWeight: "bold", mb: 2.5,
        [theme.breakpoints.up("md")]: {
            padding: `${16 * scaleFactor / 16}vw ${24 * scaleFactor / 16}vw`,
            fontSize: `${28 * scaleFactor / 16}vw`,
            // lineHeight: `${12 * scaleFactor / 16}vw`,
            marginBottom: `${20 * scaleFactor / 16}vw`
        }
    })}>
        {title}
      </Typography>
      <Typography variant="h4"
                  sx={(theme) => ({
                      mt: 2.25, mb: 6.25, color:"newGrey.dark",
                          [theme.breakpoints.up("md")]: {
                              fontSize: `${14 * scaleFactor / 16}vw`,
                              lineHeight: `${22 * scaleFactor / 16}vw`,
                              marginBottom: `${6.25 * 8 * scaleFactor / 16}vw`,
                              marginTop: `${2.25 * 8 * scaleFactor / 16}vw`,
                          }
                  })}>
        {subTitle}
      </Typography>
        <Box
            component="img"
            src="/images/agents/delete-photo.svg"
            alt="delete"
            sx={(theme) => ({
                height: "160px",
                width: "240px",
                borderBottom: "1px solid #141414",
                marginBottom: "36px",
                [theme.breakpoints.up("md")]: {
                    height: `${160 * scaleFactor / 16}vw`,
                    width: `${240 * scaleFactor / 16}vw`,
                    marginBottom: `${36 * scaleFactor / 16}vw`,
                    borderBottom: `${1 * scaleFactor / 16}vw solid #141414`,
                },
            })}
        />
      <form onSubmit={(e) => {
        e.preventDefault();
        onSubmit()
      }}>
        <SubmitResetGroup
            className={'a9090'}
          addLabel={addLabel !== null ? addLabel : t('delete')}
          cancelLabel={cancelLabel !== null ? cancelLabel : t('cancelTitle')}
          handleClose={() => {
          onCancel ? onCancel() : handleClose()
        }} loading={loading} />
      </form>
    </Box>
  </Dialog>);
}
