import ClearIcon from "@mui/icons-material/Clear";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import React from 'react';
import { useTranslation } from "react-i18next";
import { FilterSelect } from "./CheckboxSelect";
import useResize from "../../config/themes/responsive";

export const RangeSelector = ({ initialState, isForm, options, placeholder, symbol, value, onChange }) => {
  const { t } = useTranslation(["translation", "form"]);
  const scaleFactor = useResize();

  const vw = (px) => `${(px * scaleFactor) / 16}vw`;
  const handleOptionChange = (event) => {
    onChange(event.target.value);
  };

  const handleInputFromChange = (event) => {
    const price = event.target.value.replace(/[^0-9]/g, '');
    const from = price ? Number.parseInt(price) : "";

    onChange({ ...value, from });
  };

  const handleInputToChange = (event) => {
    const price = event.target.value.replace(/[^0-9]/g, '');
    const to = price ? Number.parseInt(price) : "";

    onChange({ ...value, to });
  };

  const handleClear = () => onChange(initialState);

  return (
    <FilterSelect
      onClose={() => {
        setTimeout(() => {
          document.activeElement.blur();
        }, 0);
      }}
      onChange={handleOptionChange}
      size="small"
      renderValue={(selected) => selected.from && selected.to
        ? `${t('from')} ${selected.from}${symbol} ${String(t('to')).toLocaleLowerCase()} ${selected.to}${symbol}`
        : selected.from ? `${t('from')} ${selected.from}${symbol}`
          : selected.to ? `${t('to')} ${selected.to}${symbol}` : ''}
      endAdornment={
        <ClearIcon
          fontSize="small"
          onClick={handleClear}
          sx={{ display: value.from || value.to ? "" : "none", cursor: "pointer" }}
        />
      }
      fullWidth
      sx={(theme) => ({
        mb: 0.5,
        ml: 0,
        mt: isForm ? 1 : 0,
        '& .MuiSelect-select .notranslate::after': placeholder ? {
          content: `"${placeholder}"`, opacity: 0.42,
        } : {},
        "& .MuiSelect-icon": { display: value.from || value.to ? 'none' : '' },
        [theme.breakpoints.up("md")]: {
          marginTop: vw(8),
         /* marginBottom: vw(4),
          fontSize: vw(14),
          paddingRight: vw(14),
          borderRadius: vw(4),
          lineHeight: vw(24),*/
          '& .MuiSelect-select': {
            padding: `${vw(8.5)} ${vw(32)} ${vw(8.5)} ${vw(14)} !important`,
            borderRadius: vw(4),
            minHeight: vw(24),
            fontSize: vw(14),
            lineHeight: vw(22),
          },
          '& svg.MuiSvgIcon-root': {
            width: vw(20),
            height: vw(20),
            fontSize: vw(20),
          },
          '& svg.MuiSelect-icon': {
            top: `calc(50% - ${vw(12)})`,
            right: vw(7),
            width: vw(24),
            height: vw(24),
            fontSize: vw(24),
          }
        },
      })}
      value={!value.from && !value.to ? "" : value}
      variant={isForm ? "filled" : "outlined"}
    >
      {options.map((option, key) => <MenuItem key={key}
                                              value={{ from: option.from, to: option.to }}
                                              sx={(theme) => ({
                                                [theme.breakpoints.up("md")]: {
                                                  padding: `${vw(6)} ${vw(16)}`,
                                                  marginTop: vw(0) + '!important',
                                                  '& .MuiTypography-root': {
                                                    fontSize: vw(16),
                                                    lineHeight: vw(24),
                                                  }
                                                },
                                              })}
      >{option.name}</MenuItem>)}
      <MenuItem
          onKeyDown={(e) => e.stopPropagation()}
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              padding: `${vw(6)} ${vw(16)}`,
              marginTop: vw(0) + '!important',
            },
          })}
      >
        <TextField
          fullWidth
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              '& .MuiInputBase-adornedEnd': {
                paddingRight: vw(12),
                lineHeight: vw(23),
                fontSize: vw(16),
                borderTopLeftRadius: vw(4),
                borderTopRightRadius: vw(4),
              },
              '& .MuiInputBase-adornedEnd:before': {
                borderWidth: vw(1)
              },
              '& .MuiInputBase-inputAdornedEnd': {
                height: vw(22),
                lineHeight: vw(22),
                fontSize: vw(14),
                padding: vw(12),
              }
            },
          })}
          InputProps={{
            endAdornment: <InputAdornment
                position="end"
                sx={(theme) => ({
                  [theme.breakpoints.up("md")]: {
                    marginLeft: vw(8),
                    maxHeight: vw(19),
                    height: vw(1),
                    '& .MuiTypography-body1':{
                      lineHeight: vw(24),
                      fontSize: vw(16),
                    }
                  },
                })}
            >{symbol}</InputAdornment>
          }}
          onClick={(e) => e.stopPropagation()}
          onChange={handleInputFromChange}
          placeholder={t('from')}
          value={value.from}
          variant="filled"
        />
      </MenuItem>
      <MenuItem
          onKeyDown={(e) => e.stopPropagation()}
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              padding: `${vw(6)} ${vw(16)}`,
              marginTop: vw(0) + '!important',
            },
          })}
      >
        <TextField
          fullWidth
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              '& .MuiInputBase-adornedEnd': {
                paddingRight: vw(12),
                lineHeight: vw(23),
                fontSize: vw(16),
                borderTopLeftRadius: vw(4),
                borderTopRightRadius: vw(4),
              },
              '& .MuiInputBase-adornedEnd:before': {
                borderWidth: vw(1)
              },
              '& .MuiInputBase-inputAdornedEnd': {
                height: vw(22),
                lineHeight: vw(22),
                fontSize: vw(14),
                padding: vw(12),
              }
            },
          })}
          InputProps={{
            endAdornment: <InputAdornment
                position="end"
                sx={(theme) => ({
                  [theme.breakpoints.up("md")]: {
                    marginLeft: vw(8),
                    maxHeight: vw(19),
                    height: vw(1),
                    '& .MuiTypography-body1':{
                      lineHeight: vw(24),
                      fontSize: vw(16),
                    }
                  },
                })}
            >{symbol}</InputAdornment>
          }}
          onClick={(e) => e.stopPropagation()}
          onChange={handleInputToChange}
          placeholder={t('to')}
          value={value.to}
          variant="filled"
        />
      </MenuItem>
    </FilterSelect>
  );
};