import ClearIcon from "@mui/icons-material/Clear";
import { ListItemText, MenuItem, Select, Button } from "@mui/material";
import { Checkbox } from "components";
import { useTranslation } from "react-i18next";
import React from "react";
import useResize from "../../config/themes/responsive";

export const CustomCheckboxSelect = ({ name, onChange, onClear, options, placeholder, value, openSelect, setOpenSelect, focusedRowIndex, tableRefs }) => {

    const { t } = useTranslation(["translation", "form"]);
    const scaleFactor = useResize();

    const vw = (px) => `${(px * scaleFactor) / 16}vw`;

    return (
        <Select
            fullWidth
            multiple
            open={openSelect}
            onOpen={() => {
                setOpenSelect(true);
                setTimeout(() => {
                    document.activeElement.blur();
                }, 0);
            }}
            onClose={() => {
                setOpenSelect(false);
                setTimeout(() => {
                    document.activeElement.blur();
                    if (tableRefs.current[focusedRowIndex]) {
                        tableRefs.current[focusedRowIndex].focus();
                    }
                }, 0);
            }}
            endAdornment={
                <ClearIcon fontSize="small" onClick={onClear} sx={{ display: value.length > 0 ? "" : "none", cursor: "pointer" }} />
            }
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            renderValue={(selected) => options.filter((option) => selected.includes(option.value)).map((option) => option.name).join(', ')}
            size="small"
            sx={(theme) => ({
                fontSize: "14px",
                mb: 0.5, '& .MuiSelect-select .notranslate::after': placeholder ? {
                    content: `"${placeholder}"`, opacity: 0.42,
                } : {},
                "& .MuiSelect-iconOutlined": { display: value.length > 0 ? 'none' : '' },
                [theme.breakpoints.up("md")]: {
                    marginBottom: vw(4),
                    fontSize: vw(14),
                    paddingRight: vw(14),
                    borderRadius: vw(4),
                    lineHeight: vw(24),
                    '& .MuiSelect-select': {
                        padding: `${vw(8.5)} ${vw(32)} ${vw(8.5)} ${vw(14)} !important`,
                        borderRadius: vw(4),
                        minHeight: vw(24),
                    },
                    '& svg.MuiSvgIcon-root': {
                        width: vw(20),
                        height: vw(20),
                        fontSize: vw(20),
                    },
                    '& svg.MuiSelect-icon': {
                        top: `calc(50% - ${vw(12)})`,
                        right: vw(7),
                        width: vw(24),
                        height: vw(24),
                        fontSize: vw(24),
                    }
                },
            })}
            value={value}
            >
            {options.map((option, key) => <MenuItem
                key={key}
                value={option.value}
                sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        padding: `${vw(6)} ${vw(16)}`,
                        marginTop: vw(0) + '!important',
                        '& .MuiTypography-root': {
                            fontSize: vw(16),
                            lineHeight: vw(24),
                        }
                    },
                })}
            >
                <Checkbox
                    checked={value.includes(option.value)} />
                <ListItemText primary={option.name} />
            </MenuItem>)}

            <Button
                size="small"
                variant="contained"
                sx={(theme) => ({
                    display: 'block',
                    margin: '10px auto',
                    color: (theme) => theme.palette.main,
                    boxShadow: 'none',
                    p: "3px 8px",
                    '&:hover': {
                        boxShadow: 'none',
                        color: "white"
                    },
                    [theme.breakpoints.up("md")]: {
                        padding: `${vw(3)} ${vw(8)}`,
                        margin: `${vw(10)} auto`,
                        minWidth: vw(64),
                        fontSize: vw(13) + '!important',
                        lineHeight: vw(22) + '!important',
                    },
                })}
                onClick={() => {
                    setOpenSelect(false);
                    setTimeout(() => {
                        document.activeElement.blur();
                        if (tableRefs.current[focusedRowIndex]) {
                            tableRefs.current[focusedRowIndex].focus();
                        }
                    }, 0);
                }}
            >
                {t('oksave')}
            </Button>

        </Select>
    );
}
