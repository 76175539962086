import ApiDefault from "./ApiDefault";

const PATH = 'v1/agencies';

export default class Agency extends ApiDefault {

    async create(data) {
        return this.post(PATH, data);
    }

    async update(data) {
        return this.patch(PATH, data);
    }

    async delete() {
        return this.remove(PATH);
    }
}
