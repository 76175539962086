import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Box, Button, FormHelperText, LinearProgress, Typography } from "@mui/material";
import { Avatar } from "components";
import { imagesPath } from "config";
import React from 'react';
import { useTranslation } from "react-i18next";
import useResize from "../../config/themes/responsive";

export function AvatarField({ avatar, avatarFile, entity, handleUpload, message, progress }) {
  const { t } = useTranslation(["translation", "form"]);
  const scaleFactor = useResize();

  const vw = (px) => `${(px * scaleFactor) / 16}vw`;

  return <>
    <Typography
        variant="h4"
        mt={3.75}
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            marginTop: vw(30),
            lineHeight: vw(22),
            fontSize: vw(14),
          },
        })}
    >
      {t('photo')}
    </Typography>
    <Box
         sx={(theme) => ({
           alignItems: "center",
           display: "flex",
           mt: 2.5,
           [theme.breakpoints.up("md")]: {
             marginTop: vw(20),
           },
         })}
    >
      <Avatar
          sx={{
              height: 39,
              mr: 3,
              width: 39,
              color: "#826BFF" }}
          src={entity?.avatar ? imagesPath + entity.avatar.smallImage : avatar} />

      {avatarFile ?
        <Box
            sx={(theme) => ({
              width: 276,
              height: 32,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            })}
        >
          <Typography sx={{
            maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"
          }}>{avatarFile.name}</Typography>
          <LinearProgress variant="determinate" value={progress} color="success" />
        </Box> : <Button
          color="text"
          component="label"
          startIcon={<FileDownloadOutlinedIcon />}
          sx={(theme) => ({
              fontWeight: "bold",
              [theme.breakpoints.up("md")]: {
                  borderRadius: vw(4),
                  padding: `${vw(6)} ${vw(8)}`,
                  lineHeight: vw(24),
                  minWidth: vw(64),
                  fontSize: vw(14),
                  '& .MuiButton-startIcon': {
                      marginRight: vw(8),
                      marginLeft: vw(-4),
                  },
                  '& .MuiSvgIcon-root': {
                      width: vw(20),
                      height: vw(20),
                      fontSize: vw(20),
                  }
              },
          })}
          variant="text"
        >
          {t('uploadPhoto')}
          <input hidden accept="image/*" multiple type="file" name="avatarPhoto" onChange={(e) => handleUpload(e)} />
        </Button>}
      {message && <FormHelperText error={true}>{message}</FormHelperText>}
    </Box>
  </>
}