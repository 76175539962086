import ApiDefault from "./ApiDefault";

const PATH = 'v1/clients';

export default class Client extends ApiDefault {
  async create(data) {
    return this.post(PATH, data);
  }

  async update(data) {
    return this.patch(PATH + `/${data.id}`, data);
  }

  async updatePrevResponsible(data) {
    return this.patch(PATH + `/prev-responsible/${data.id}`, data);
  }

  async all() {
    return this.get(PATH);
  }

  async once(id, page) {
    return this.get(PATH + '/' + id + (page > 1 ? '?page='+ String(page) : ''));
  }

  async delete(id) {
    return this.remove(PATH + '/' + id);
  }

  async getOne(id) {
    return this.get(PATH + '/' + id);
  }

  async updateOrder(data) {
    return this.put(PATH + "/update-order", data);
  }
}
