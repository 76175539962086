import { DialogTitle, IconButton } from "@mui/material";
import { ReactComponent as Close } from "assets/close.svg";
import React from 'react';
import useResize from "../../config/themes/responsive";

export function CloseDialog({ handleClose }) {
  const scaleFactor = useResize();

  return <DialogTitle sx={(theme) => ({
      [theme.breakpoints.up("md")]: {
          padding: `${16 * scaleFactor / 16}vw ${24 * scaleFactor / 16}vw`,
      },
  })}>
    <IconButton
      aria-label="close"
      color="text"
      onClick={handleClose}
      sx={(theme) => ({
          position: "absolute",
          right: '1rem',
          top: '1rem',
          height: 48,
          width: 48,
          backgroundColor: 'transparent',
          [theme.breakpoints.up("md")]: {
              right: `${16 * scaleFactor / 16}vw`,
              top: `${16 * scaleFactor / 16}vw`,
              width: `${48 * scaleFactor / 16}vw`,
              height: `${48 * scaleFactor / 16}vw`,
              padding: `${8 * scaleFactor / 16}vw`,
              '& svg': {
                  width: `${24 * scaleFactor / 16}vw`,
                  height: `${24 * scaleFactor / 16}vw`,
              }
          },
      })}
    >
      <Close width={24} height={24} />
    </IconButton>
  </DialogTitle>
}
