import { Typography } from "@mui/material";
import { PasswordField } from "../passwordField/PasswordField";
import React from 'react';
import useResize from "../../config/themes/responsive";

export const LabeledPassword = (
  {
    field,
    formik,
    label,
    placeholder,
    required,
  }
) => {
    const scaleFactor = useResize();
  return <>
    <Typography className={required && "label"} variant="h4" mt={{ xs: 1.5, md: 3.75 }} mb={1} sx={(theme) => ({
        [theme.breakpoints.up("md")]: {
            marginTop: `${30 * scaleFactor / 16}vw`,
            fontSize: `${14 * scaleFactor / 16}vw`,
            lineHeight: `${22 * scaleFactor / 16}vw`,
            marginBottom: `${8 * scaleFactor / 16}vw`,
        },
    })} >
      {label}
    </Typography>
    <PasswordField
      autoComplete="password"
      className={'field-label-tab2'}
      error={formik.errors[field] && formik.touched[field]}
      helperText={formik.touched[field] && formik.errors[field]}
      name={field}
      onChange={formik.handleChange}
      placeholder={placeholder}
      variant="filled"
      sx={(theme) => ({
          xs: 1.5,
          [theme.breakpoints.up("md")]: {
              marginTop: `${8 * scaleFactor / 16}vw`,
              marginBottom: `${4 * scaleFactor / 16}vw`,
              paddingRight: `${12 * scaleFactor / 16}vw`,
              borderTopLeftRadius: `${4 * scaleFactor / 16}vw`,
              borderTopRightRadius: `${4 * scaleFactor / 16}vw`,
              fontSize: `${16 * scaleFactor / 16}vw`,
              lineHeight: `${23 * scaleFactor / 16}vw`,
              '& .MuiFilledInput-root': {
                  fontSize: `${16 * scaleFactor / 16}vw`,
                  lineHeight: `${23 * scaleFactor / 16}vw`,
              },
              '& .MuiFilledInput-input': {
                  padding: `${12 * scaleFactor / 16}vw`,
                  fontSize: `${14 * scaleFactor / 16}vw`,
                  lineHeight: `${22 * scaleFactor / 16}vw`,
                  height: `${22 * scaleFactor / 16}vw`,
              },
              '&.field-label-tab2 .MuiInputBase-root .MuiInputAdornment-root': {
                  marginLeft: `${8 * scaleFactor / 16}vw`,
              },
              '&.field-label-tab2 .MuiInputBase-root .MuiInputAdornment-root p': {
                  fontSize: `${16 * scaleFactor / 16}vw`,
                  lineHeight: `${24 * scaleFactor / 16}vw`,
              },
              '&.field-label-tab2 .MuiInputBase-root': {
                  paddingRight: `${12 * scaleFactor / 16}vw !important`,
              },
              '&.field-label-tab2 .MuiInputAdornment-root': {
                  height: `${0.16 * scaleFactor / 16}vw;`,
                  maxHeight: `${32 * scaleFactor / 16}vw`,
                  marginLeft: `${8 * scaleFactor / 16}vw`,
              },
              '&.field-label-tab2 .MuiInputAdornment-root .MuiButtonBase-root': {
                  marginRight: `${-12 * scaleFactor / 16}vw;`,
                  fontSize: `${24 * scaleFactor / 16}vw`,
                  padding: `${8 * scaleFactor / 16}vw`,
              },
              '&.field-label-tab2 .MuiInputAdornment-root .MuiButtonBase-root .MuiSvgIcon-root': {
                  height: `${24 * scaleFactor / 16}vw;`,
                  width: `${24 * scaleFactor / 16}vw;`,
              }
          },
      })}
    />
  </>
}
