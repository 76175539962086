import { Checkbox as MuiCheckbox, } from "@mui/material";
import { ReactComponent as UncheckedIcon } from 'assets/checkbox_uncheked.svg';
import { ReactComponent as CheckedIcon } from 'assets/checkbox_cheked.svg';
import { ReactComponent as IndeterminateIcon } from 'assets/checkbox_indeterminate.svg';
import useResize from "../../config/themes/responsive";
export const Checkbox = ({ children, checked, ...props }) => {
    const scaleFactor = useResize();
    const vw = (px) => `${(px * scaleFactor) / 16}vw`;
    return (
        <MuiCheckbox
            {...props}
            checked={checked}
            sx={(theme) => ({
                color: checked ? "unset" : "newGrey.light",
                [theme.breakpoints.up("md")]: {
                    marginTop: vw(0) + '!important',
                    padding: vw(9),
                    '& svg': {
                        width: vw(18),
                        height: vw(18),
                    }
                },
            })}
            icon={<UncheckedIcon className="unchecked-icon" />}
            checkedIcon={<CheckedIcon />}
            indeterminateIcon={<IndeterminateIcon />}
        >
            {children}
        </MuiCheckbox>
    );
};