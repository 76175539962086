import { t } from "i18next";

export const convertDateToUTC = (date) => {
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
}

export const filterData = (filter) => {

  const data = {id: null, type: null, head: null};
        
  if (filter) {

      data.id = filter.id;
      data.type = filter.type;
      data.head = filter.head;
  }

  return data;
}

export const randomColor = () => {
  const hex = Math.floor(Math.random() * 0xffffff);

  return "#" + hex.toString(16);
};

export const stringToColour = (str, opacity = 0.5) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  const bigint = parseInt(colour, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function getTypeShortName(type) {
  let result = "";
  if (!type) {
    return result;
  }
  switch (type) {
    case "селище":
      result = " сщ. "
      break
    case "площа":
      result = " пл. "
      break
    case "провулок":
      result = " пров. "
      break
    case "проспект":
      result = " пр. "
      break
    case "вулиця":
      result = " вул. "
      break
    case "бульвар":
      result = " бл. "
      break
    case "смт":
      result = " смт ";
      break
    case "сільська рада":
      result = " с.р. ";
      break
    case "селищна рада":
      result = " сщ. р. "
      break
    case " село":
    case " місто":
    default:
      result = type.charAt(0) + ". "
  }

  return result;
}

export function getAddress(apartment, city = true) {
  return (city && apartment.city ? apartment.city.name : '')
    + (apartment.street ? `, ${getTypeShortName(apartment.street.type)}` + apartment.street.name + (apartment.houseNumber ? `, ${apartment.houseNumber}` : '')  : '');
}

export function getFullAddress(apartment) {

  let result = '';

  if (apartment.city) {
    result += apartment.city.name
  }

  if (apartment.cityRegion) {
    result += `, р-н ${apartment.cityRegion.name}`
  }

  if (apartment.citySubregion) {
    result += `, мкр-н ${apartment.citySubregion.name}`
  }

  if (apartment.street) {

    if (apartment.street.type) {
      result += `, ${getTypeShortName(apartment.street.type)}`
    }

    result += ` ${apartment.street.name}`

    if (apartment.houseNumber) {
      result += `, ${apartment.houseNumber}`
    }
  }

  return result;
}

export function getApartmentTitle(apartment) {
  let type = '';
  if (apartment.propertyType.value === 0) {
    type = apartment.rooms + ` ${t('_flat')}, `
  } else if (apartment.propertyType.value === 1) {
    type = ` ${t('_commercial')}, `
  } else if (apartment.propertyType.value === 2) {
    type = apartment.rooms + ` ${t('_house')}, `
  } else if (apartment.propertyType.value === 3) {
    type = apartment.rooms + ` ${t('_ground')}, `
  }
  return (apartment.operationType.value === 0 ? t('sell') : t('rent')) + ' ' + type + getAddress(apartment, false)
}

export function getPropertyTitle(property)
{
  let type = '';
  if (property.propertyType.value === 0) {
    type = property.rooms + '-' + t('__rooms') + `. ${t('flat').toLowerCase()}, `
  } else if (property.propertyType.value === 1) {
    type = `${t('commercial')}, `
  } else if (property.propertyType.value === 2) {
    type = property.rooms + '-' + t('__rooms') +`. ${t('house').toLowerCase()}, `
  } else if (property.propertyType.value === 3) {
    type = `${t('ground')}, `
  }

  return type + `${property.totalArea} м²`
}

export function parseDate(strDate) {
  return new Date(Date.parse(strDate));
}

export function getLinkForMessenger(type, url, isMobile) {
  let link = "";
  switch (type) {
    case "link":
      navigator.clipboard.writeText(url)
      break;
    case "viber":
      // link = "viber://chat?text=";
      link = "viber://forward?text="
      break;
    case "whatsapp":
      // link = "https://web.whatsapp.com/send?text=";
      // link = `https://web.whatsapp.com/send?text=`;
      link = isMobile ? "https://wa.me/?text=" : 'https://web.whatsapp.com/send?text=';
      // link = 'https://web.whatsapp.com/send?text=';
      break;
    case "telegram":
    default:
      link = "tg://msg_url?url="; //
      // link = "http://t.me/share/url?url=&text="; //
      // link = "t.me/share?url="
  }

  return link;
}