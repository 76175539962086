import { Box, Dialog, Grid, Typography, } from "@mui/material";
import { CloseDialog, TimePickerInput, LabeledInput, SubmitResetGroupCustom, SubmitResetGroup } from "components";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { calendarActions } from "store";
import * as Yup from "yup";
import useResize from "../../config/themes/responsive";

export default function EventDialog({ open, handleClose, event }) {
  const { t } = useTranslation(["translation", "form"]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('required_field')),
    dateFrom: Yup.date().required(t('required_field')),
    dateTo: Yup.date().required(t('required_field')),
  });
  const scaleFactor = useResize();

  const fromDate = new Date();
  const minutes = fromDate.getMinutes();
  const roundedMinutes = Math.round(minutes / 30) * 30;
  fromDate.setMinutes(roundedMinutes);
  fromDate.setSeconds(0, 0);

  const formik = useFormik({
    initialValues: event || {
      title: "",
      dateFrom: fromDate,
      dateTo: new Date(fromDate.getTime() + 30 * 60000)
    }, enableReinitialze: true, validateOnChange: false, validationSchema: validationSchema, onSubmit: (values) => {
      setLoading(true);
      const data = { ...values }
      if (event) {
        data.id = event.id;
        data.clientId = event.clientId;
      }

      if (event.id) {
        dispatch(calendarActions.update(data)).then(() => {
          setLoading(false);
          handleClose();
        });
      } else {
        dispatch(calendarActions.create(data)).then(() => {
          setLoading(false);
          handleClose();
        });
      }
    },
  });
  return (<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true} sx={(theme) => ({
    [theme.breakpoints.up("md")]: {
      '& .MuiPaper-elevation': {
        borderRadius: `${4 * scaleFactor / 16}vw`,
        maxHeight: `calc( 100% - ${64 * scaleFactor / 16}vw)`,
        width: `calc( 100% - ${64 * scaleFactor / 16}vw)`,
        maxWidth: `${600 * scaleFactor / 16}vw`,
        margin: `${32 * scaleFactor / 16}vw`,
      },
    },
  })}
  className={'event-dialog-popup'}>
    <CloseDialog handleClose={handleClose} />
    <Box
        sx={(theme) => ({
          margin: "46px 46px 78px 46px",
          [theme.breakpoints.up("md")]: {
            margin: `${46 * scaleFactor / 16}vw ${46 * scaleFactor / 16}vw ${76 * scaleFactor / 16}vw ${46 * scaleFactor / 16}vw`,
          },
        })}
    >
      <Typography variant="h2"
                  sx={(theme) => ({
                    fontWeight: "bold",
                    [theme.breakpoints.up("md")]: {
                      fontSize: `${28 * scaleFactor / 16}vw`,
                    },
                  })}>
        {event?.title ? t('eventEdit') : t('eventNew')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <LabeledInput
          autoFocus={true}
          field="title"
          formik={formik}
          label={t('title')}
          placeholder={t('titleType')}
          required
        />
        <Grid
          container
          rowSpacing={1}
          columnSpacing={2}
          className={'container-form'}
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              '& h4': {
                marginTop: `${30 * scaleFactor / 16}vw`,
                fontSize: `${14 * scaleFactor / 16}vw`,
                lineHeight: `${22 * scaleFactor / 16}vw`,
              },
              '& .MuiFormControl-marginDense': {
                marginTop: `${8 * scaleFactor / 16}vw`,
                marginBottom: `${4 * scaleFactor / 16}vw`,
              },
              '& .MuiInputBase-formControl' : {
                fontSize: `${16 * scaleFactor / 16}vw`,
                lineHeight: `${23 * scaleFactor / 16}vw`,
                paddingRight: `${12 * scaleFactor / 16}vw`,
              },
              '& .MuiFilledInput-input': {
                padding: `${12 * scaleFactor / 16}vw`,
                fontSize: `${14 * scaleFactor / 16}vw`,
                lineHeight: `${22 * scaleFactor / 16}vw`,
                height: `${22 * scaleFactor / 16}vw`,
              },
              '& .MuiIconButton-edgeEnd': {
                padding: `${8 * scaleFactor / 16}vw`,
                fontSize: `${24 * scaleFactor / 16}vw`,
                marginRight: `${-12 * scaleFactor / 16}vw`,
              },
              '& .MuiSvgIcon-fontSizeMedium': {
                height: `${24 * scaleFactor / 16}vw`,
                width: `${24 * scaleFactor / 16}vw`,
              },
            },
          })}
        >
          <Grid item xs={6}
                sx={(theme) => ({
                  [theme.breakpoints.up("md")]: {
                    '&.MuiGrid-item': {
                      //paddingLeft: `${16 * scaleFactor / 16}vw`,
                      paddingTop: `${8 * scaleFactor / 16}vw`,
                    }
                  },
                })}
          >
            <TimePickerInput
              field="dateFrom"
              formik={formik}
              label={t('startTme')}
              placeholder={t('chooseStartTme')}
            />
          </Grid>
          <Grid item xs={6} sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              '&.MuiGrid-item': {
                paddingLeft: `${16 * scaleFactor / 16}vw`,
                paddingTop: `${8 * scaleFactor / 16}vw`,
              }

            },
          })}>
            <TimePickerInput
              field="dateTo"
              formik={formik}
              label={t('endTime')}
              placeholder={t('chooseEndTme')}
            />
          </Grid>
        </Grid>
          {
            formik.values.id ?
            <SubmitResetGroupCustom formik={formik} addLabel={event ? t('save') : t('add')} handleClose={handleClose} loading={loading} />
            :
            <SubmitResetGroup formik={formik} addLabel={event ? t('save') : t('add')} handleClose={handleClose} loading={loading} />
          }
      </form>
    </Box>
  </Dialog>);
}
