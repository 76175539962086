import { iconButtonClasses, Typography } from "@mui/material";
import { DatePicker, datePickerToolbarClasses, LocalizationProvider, TimePicker, } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/ru';
import React from 'react';
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import useResize from "../../config/themes/responsive";

export const DatePickerInput = ({ field, formik, label, placeholder, isTime = false, required = true }) => {
  const commonProps = {
    value: dayjs(formik.values[field]),
    onChange: (value) => formik.setFieldValue(field, value ? value.toDate() : null, true),
    slotProps: {
      textField: {
        margin: "dense",
        name: field,
        placeholder: placeholder,
        type: "text",
        fullWidth: true,
        variant: "filled",
        error: false
      }
    }
  }
  const scaleFactor = useResize();

  const vw = (px) => `${(px * scaleFactor) / 16}vw`;

  const Component = isTime ? StyledTimePicker : StyledDatePicker;
  return <>
    <Typography
        className={required && "label dataPicker_911"}
        variant="h4"
        sx={(theme) => ({
          mt: 3.75,
          [theme.breakpoints.up("md")]: {
            marginTop: vw(30),
            lineHeight: vw(22),
            fontSize: vw(14)
          },
        })}
    >
      {label}
    </Typography>
    <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={'ua'}
    >
      <Component
          {...commonProps}
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              marginTop: vw(8),
              marginBottom: vw(4),
              '& .MuiFilledInput-root': {
                paddingRight: vw(12),
                lineHeight: vw(23),
                fontSize: vw(16),
              },
              '& .MuiInputBase-input': {
                height: vw(22),
                lineHeight: vw(22),
                padding: vw(12),
                fontSize: vw(14),
              },
              '& .MuiIconButton-root': {
                marginRight: vw(-12),
                padding: vw(8),
                fontSize: vw(24),
              },
              '& .MuiSvgIcon-root': {
                fontSize: vw(24),
                height: vw(24),
                width: vw(24),
              }
            },
          })}
      />
    </LocalizationProvider>
  </>
}

const StyledTimePicker = styled(TimePicker)({
  [`& .${iconButtonClasses.root}`]: {
    backgroundColor: 'transparent',

    "& :hover": {
      backgroundColor: 'transparent',
    }
  },
});

const StyledDatePicker = styled(DatePicker)({
  [`& .${iconButtonClasses.root}`]: {
    backgroundColor: 'transparent',
    "& :hover": {
      backgroundColor: 'transparent',
    }
  },
  [`& .${datePickerToolbarClasses.root}`]:
    {
      [`& .${iconButtonClasses.root}`]: {
        backgroundColor: 'transparent',
        "& :hover": {
          backgroundColor: 'transparent',
        }
      },
    }
});
