import { InputAdornment, TextField, Typography } from "@mui/material";
import React from 'react';
import useResize from "../../config/themes/responsive";

export const LabeledInput = ({autoFocus, disabled, endAdornment, field, formik, label, multiline, placeholder, required, rows}) => {
  const scaleFactor = useResize();
  const vw = (px) => `${(px * scaleFactor) / 16}vw`;

  const handleChangeValuePreview = (event, newValue) => {

    if (event) {

      if (JSON.parse(localStorage.getItem('currentPreview')) !== null) {

        const apartmentsList = JSON.parse(localStorage.getItem('currentPreview'));

        const currentApartment = apartmentsList.map(apartment => {
          if (apartment.id === formik.values.id) {
            return {...apartment, [field]: newValue}
          }
          return apartment;
        })

        localStorage.setItem('currentPreview', JSON.stringify(currentApartment))
      }

    }
  }

  return <>
    <Typography className={required && "label"} variant="h4"
                mt={{ xs: 1.5, md: 3.75 }}
                sx={(theme) => ({
                  [theme.breakpoints.up("md")]: {
                    marginTop: vw(30),
                    fontSize: vw(14),
                    lineHeight: vw(22),
                  },
                })}>
      {label}
    </Typography>
    <TextField
      autoFocus={autoFocus}
      disabled={disabled}
      className={'field-label-tab2'}
      InputProps={{
        endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
        sx: multiline && { padding: "0" }
      }}

      error={formik.errors[field] && formik.touched[field]}
      fullWidth
      helperText={formik.touched[field] && formik.errors[field]}
      hiddenLabel
      margin="dense"
      multiline={multiline}
      name={field}
      // onChange={(event, newValue) => handleChange(event, newValue)}
      onChange={(event) => {
        formik.setFieldError(event.target.name, undefined);
        formik.handleChange(event)
        handleChangeValuePreview(event, event.target.value)
      }}
      placeholder={placeholder}
      rows={rows}
      type="text"
      variant="filled"
      value={formik.values[field]}
      sx={(theme) => ({
        xs: 1.5,
        [theme.breakpoints.up("md")]: {
          '& .MuiFilledInput-root': {
            fontSize: vw(16),
            lineHeight: vw(23),
          },
          '& .MuiFilledInput-input': {
            padding: vw(12),
            fontSize: vw(14),
            lineHeight: vw(22),
            height: vw(22),
          },
          '&.field-label-tab2 .MuiInputBase-root .MuiInputAdornment-root': {
            marginLeft: vw(8),
          },
          '&.field-label-tab2 .MuiInputBase-root .MuiInputAdornment-root p': {
            fontSize: vw(16),
            lineHeight: vw(24)
          },
          /*'&.field-label-tab2 .MuiInputBase-root': {
            paddingRight: `${12 * scaleFactor / 16}vw`,
          },*/

          marginTop: vw(8),
          marginBottom: vw(4),
        },
        '& p.MuiFormHelperText-root.Mui-error': {
          fontSize: vw(14),
          marginLeft: vw(14),
          marginRight: vw(14),
          marginTop: vw(3),
          lineHeight: vw(26),
        }
      })}
    />
  </>
}
