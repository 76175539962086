import ApiDefault from "./ApiDefault";

const PATH = 'v1/enums';

export default class Enum extends ApiDefault {

    async list(data) {
        return this.get(PATH + `?type=${data.type}`);
    }
}
