import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography, } from "@mui/material";
import useResize from "../../config/themes/responsive";

export function TableBlock({
  headerChild,
  footerChild,
  logoPath,
  rows = [],
  subtitle,
  title,

}) {
    const scaleFactor = useResize();
    const vw = (px) => `${(px * scaleFactor) / 16}vw`;

  return (
    <Grid
      item
      xs={12}
      sx={(theme) => ({
          mb: {xs: 2.8, xl: 3},
          padding: "18px 24px",
          backgroundColor: "newGrey.veryLight",
          [theme.breakpoints.up("md")]: {
              padding: `${vw(18)} ${vw(24)}`,
              marginBottom: vw(24) + '!important',
          },
      })}
    >
      <Box
        sx={(theme) => ({
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: { xs: "48px", xl: "60px" },
            [theme.breakpoints.up("md")]: {
                paddingBottom: vw(60) + '!important',
            },
        })}
      >
        <Box>
          <Typography
              variant="h3"
              sx={(theme) => ({
                  fontWeight: "bold",
                  mt: 1,
                  [theme.breakpoints.up("md")]: {
                      fontSize: vw(20),
                      marginTop: vw(8),
                  },
              })}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            sx={(theme) => ({
                fontSize: { xs: "14px", xl: "16px" },
                [theme.breakpoints.up("md")]: {
                    fontSize: vw(16) + '!important',
                    lineHeight: vw(22),
                },
            })}
          >
            {subtitle}
          </Typography>
          {headerChild}
        </Box>
        <Box
            component="img"
            src={logoPath ? logoPath : "/images/icon/scheme.svg"}
            height={"60px"}
            alt="logo"
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                  height: vw(60)
              },
            })}
        />
      </Box>
      <TableContainer sx={{}}>
        <Table>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                  key={row.name}
                  sx={(theme) => ({
                      [theme.breakpoints.up("md")]: {
                          borderWidth: vw(1)
                      },
                  })}
              >
                <TableCell
                  sx={(theme) => ({
                      width: "200px",
                      p: { xs: "16px 20px 16px 0", xl: "20px 24px 20px 0" },
                      fontSize: { xs: "14px", xl: "16px" },
                      color:"newGrey.dark",
                      [theme.breakpoints.up("md")]: {
                        fontSize: vw(16) + '!important',
                        lineHeight: vw(22),
                        width: vw(200),
                        padding: `${vw(20)} ${vw(24)} ${vw(20)} ${vw(0)} !important`,
                      },
                  })}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  sx={(theme) => ({
                      fontWeight: "bold",
                      p: { xs: "16px 20px 16px 0", xl: "20px 24px 20px 0" },
                      fontSize: { xs: "14px", xl: "16px" },
                      color:"dark",
                      [theme.breakpoints.up("md")]: {
                          fontSize: vw(16) + '!important',
                          lineHeight: vw(22),
                          padding: `${vw(20)} ${vw(24)} ${vw(20)} ${vw(0)} !important`,
                      },
                  })}
                  align="left"
                >
                  {row.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {footerChild && <Box
          sx={(theme) => ({
              margin: "30px 0 0 20px",
              [theme.breakpoints.up("md")]: {
                  margin: `${vw(30)} ${vw(0)} ${vw(0)} ${vw(20)}`,
              },
        })}
      >{footerChild}</Box>}
    </Grid>
  );
}
