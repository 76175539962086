import { createTheme } from "@mui/material/styles";

const getLightTheme = (scaleFactor) =>
    createTheme({
      palette: {
        mode: 'light',
        background: {
          black: '#000',
          white:'#FFF'
        },
        primary: {
          light: "#7ad3fb",
          main: "#59C8FA",
          dark: "#1C77FF",
          contrastText: "#fff",
          mainWithOpacity: "rgba(89, 200, 250, 0.08)",
          success: "rgb(56, 142, 60)"
        },
        grey: {
          main: "#F1F1F1",
          light: "#FBFBFB"
        },
        text: {
          main: "#141414",
          white: "#FFF",
        },
        newGrey: {
          dark: "#8B9398",
          medium: "#BEC2C4",
          medium2: "#E0E1E3",
          light: "#F1F1F1",
          veryLight: "#FBFBFB",
        },
        dark: "#141414",
        black: "#444",
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 810,
          lg: 1200,
          xl: 1536,
        },
      },
      typography: {
        fontFamily: 'Fixel Regular',
        h1: {
          fontFamily: 'Fixel Bold',
          fontSize: 36,
          lineHeight: "44px",
          color: '#141414',
        },
        h2: {
          fontFamily: 'Fixel Bold',
          lineHeight: { xs: 28, sm: 34, xl: 40 },
          color: '#141414',
          '@media (min-width:0px)': {
            fontSize: 22,
          },
          '@media (min-width:1536px)': {
            fontSize: 28,
          }
        },
        h3: {
          fontFamily: 'Fixel Bold',
          fontSize: 20,
          lineHeight: { xs: 18, sm: 26, xl: 28 },
          color: '#141414',
          fontWeight: '700',
        },
        h4: {
          fontFamily: 'Fixel Regular',
          fontSize: 14,
          lineHeight: "22px",
          fontWeight: '500',
          color: '#141414',
        },
        h5: {
          fontFamily: 'Fixel Regular',
          lineHeight: "22px",
          color: "#8B9398",
          '@media (min-width:0px)': {
            fontSize: 12,
          },
          '@media (min-width:1536px)': {
            fontSize: 14,
          }
        },
        h6: {
          fontFamily: 'Fixel Bold',
          fontSize: 12,
          lineHeight: "18px",
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              backgroundColor: "#FBFBFB",
            },
          },
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              backgroundColor: "#FBFBFB",
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              backgroundColor: "#FBFBFB",
              '&.Mui-focused, &:hover': {
                backgroundColor: '#FBFBFB'
              },
            },
            input: {
              padding: "12px",
              fontFamily: 'Fixel Regular',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '22px',
              height: '22px',
              color: '#000',
            },
            placeholder: {
              color: "#8B9398",
              fontFamily: 'Fixel Regular',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '22px',
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            root: {
              '& .MuiFilledInput-root': {
                padding: "12px 39px 12px 12px",
              },
            }
          }
        }
      },
    });

export default getLightTheme;
