import ApiDefault from "./ApiDefault";

const PATH = 'v1/agents';

export default class Agent extends ApiDefault {
  async create(data) {
    return this.post(PATH, data);
  }

  async update(data) {
    return this.patch(PATH + `/${data.id}`, data);
  }

  async free() {
    return this.get(PATH + "/free");
  }

  async all() {
    return this.get(PATH);
  }

  async upload(id, file, onUploadProgress) {
    let formData = new FormData();

    formData.append("avatarPhoto", file);
    id && formData.append("id", id);

    return this.post(PATH + "/avatar", formData, onUploadProgress);
  }

  async requestAgency(email) {
    return this.post(PATH + "/agency/request", { email });
  }

  async inviteToAgency(email) {
    return this.post(PATH + "/agency/new-invite", { email });
  }

  async joinInviteAgency(id) {
    return this.post(PATH + "/agency/invite/join", { id });
  }

  async refusalAgency(id) {
    return this.post(PATH + "/agency/refusal", { id });
  }

  async refusalInviteAgency(id) {
    return this.post(PATH + "/agency/invite/refusal", { id });
  }

  async changeAgencyDirector(data) {
    return this.patch(PATH + "/agency/change-director", data);
  }

  async leaveAgency() {
    return this.put(PATH + "/agency/leave");
  }

  async leaveAgencyHead(id) {
    return this.put(PATH + "/head", { id });
  }

  async deleteAgencyUser(id) {
    return this.remove(PATH + '/' + id);
  }
}