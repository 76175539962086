import ApiDefault from "./ApiDefault";

const PATH = 'v1/apartment-boards';

export default class ApartmentBoard extends ApiDefault {

    async list(data) {
        

        // data['id'] = null;
        // data['type'] = null;
        // data['head'] = null;

        return this.get(PATH + `?id=${data.id}&type=${data.type}&head=${data.head}`);
    }

    async deleteFromBoard(data) {
        return this.post(PATH + "/delete-from-board", data)
      }

    async moveApartment(data) {
        return this.patch(PATH + "/move-apartment", data)
    }

    async clientAll(clientId) {
        return this.get(PATH + "/client/" + clientId);
    }

    async copyApartment(data) {
        return this.patch(PATH + "/copy-apartment", data)
    }

    async moveApartmentExternal(data) {
        return this.patch(PATH + "/move-apartment-external", data)
    }
}
