import ApiDefault from "./ApiDefault";

const PATH = 'v1/client-boards';

export default class ClientBoard extends ApiDefault {

    async list(data) {
        

        // data['id'] = null;
        // data['type'] = null;
        // data['head'] = null;

        return this.get(PATH + `?id=${data.id}&type=${data.type}&head=${data.head}`);
    }

    async deleteFromBoard(data) {
        return this.patch(PATH + "/delete-from-board", data)
    }

    async moveClientInternal(data) {
        return this.patch(PATH + "/move-client-internal", data)
    }

    async moveClientExternal(data) {
        return this.patch(PATH + "/move-client-external", data)
    }

    // async clientAll(clientId) {
    //     return this.get(PATH + "/client/" + clientId);
    // }

    // async copyApartment(data) {
    //     return this.patch(PATH + "/copy-apartment", data)
    // }
}
