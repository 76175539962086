import ApiDefault from "./ApiDefault";

const PATH = 'v1/presentations';

export default class Presentation extends ApiDefault {
  async create(data) {
    return this.post(PATH, data);
  }

  async getPresentation(id) {
    return this.get(PATH + "/" + id)
  }

  async setRating(id, rating) {
    return this.put(PATH + "/rating/" + id, { rating })
  }
}