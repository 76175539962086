import ApiDefault from "./ApiDefault";

const PATH = 'v1/locations';

export default class Location extends ApiDefault {
  async states(data = null) {
    return this.post(PATH + "/states", data);
  }

  async cities(data = null) {
    return this.post(PATH + "/cities", data);
  }

  async city() {
    return this.get(PATH + "/city");
  }

  async cityRegions(data = null) {
    return this.post(PATH + "/city-regions", data);
  }

  async citySubregions(data = null) {
    return this.post(PATH + "/city-subregions", data);
  }

  async streets(data = null) {
    return this.post(PATH + "/streets", data);
  }

  async residentialComplexes(data = null) {
    return this.post(PATH + "/residential-complexes", data);
  }

}