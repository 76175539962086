// import lightModeConfig from './themes/lightMode';
// import darkModeConfig from './themes/darkMode';
//
//
// export const imagesPath = process.env.REACT_APP_IMAGES_PATH
// export const themeConfig = {
//   light: lightModeConfig,
//   dark: darkModeConfig,
// };

import getLightTheme from "./themes/lightMode";
import getDarkTheme from "./themes/darkMode";

export const imagesPath = process.env.REACT_APP_IMAGES_PATH;

export const themeConfig = (scaleFactor) => ({
  light: getLightTheme(scaleFactor),
  dark: getDarkTheme(scaleFactor),
});
