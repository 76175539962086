import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Box, Breadcrumbs as MuiBreadcrumbs, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React from 'react';
import useResize from "../config/themes/responsive";

export function Breadcrumbs({ linkName, linkPath, title }) {
  const scaleFactor = useResize();
  const vw = (px) => `${(px * scaleFactor) / 16}vw`;

  const breadcrumbs = [
    <Link to={"/" + linkPath} key="1" style={{ textDecoration: "none" }}>
      <Box
          sx={(theme) => ({
            display: "flex",
            color: "primary.main",
            mr: 4,
            [theme.breakpoints.up("md")]: {
              marginRight: vw(32),
            },
          })}
      >
        <NavigateBeforeIcon
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                width: vw(24),
                height: vw(24),
                fontSize: vw(24),
                lineHeight: vw(36),
              },
            })}
        />
        <Typography
            key="1"
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                fontSize: vw(16),
                lineHeight: vw(24),
              },
            })}
        >
          {linkName}
        </Typography>
      </Box>
    </Link>,
    <Typography
        key="2"
        color="text.primary"
        variant="h2"
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            fontSize: vw(28),
            lineHeight: vw(42),
          },
        })}
    >
      {title}
    </Typography>,
    
  ];

  return <Stack spacing={2}>
    <MuiBreadcrumbs
        separator={""}
        aria-label="breadcrumb"
        sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
                '& .MuiBreadcrumbs-separator':{
                    marginLeft: vw(8),
                    marginRight: vw(8)
                }
            },
        })}
    >
      {breadcrumbs}
    </MuiBreadcrumbs>
  </Stack>
}