import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { parseDate } from "helpers";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { calendarActions } from "store";
import "./Calendar.css"
import EventDialog from "./EventDialog";
import useResize from "../../config/themes/responsive";

export function CalendarWidget({ sx }) {
  const { t, i18n } = useTranslation();
  const { user } = useSelector(x => x.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const scaleFactor = useResize();

  const { calendar } = useSelector(store => store);
  const { clientId } = useParams();

  const calendarRef = useRef(null);

  const events = calendar.map(event => {
    return {
      id: event.id,
      title: event.title,
      start: parseDate(event.dateFrom),
      end: parseDate(event.dateTo)
    }
  })

  useEffect(() => {
    dispatch(calendarActions.all())
  }, [dispatch, clientId]);


  const handlePrevDay = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    setCurrentDate(calendarApi.getDate());
  };

  const handleNextDay = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    setCurrentDate(calendarApi.getDate());
  };

  const handleSelect = (info) => {
    const { start, end } = info;
    setEvent({ dateFrom: start, dateTo: end, title: "", clientId });
    setOpen(true)
  }

  const handleEventClick = ({ event }) => {
    const { title, start, end, id } = event;
    setEvent({ dateFrom: start, dateTo: end, title, id });
    setOpen(true)
  }

  const handleEventChange = ({ event }) => {
    const { title, start, end, id } = event;
    dispatch(calendarActions.update({ dateFrom: start, dateTo: end, title, id }));
  }

  const formatDate = (date) => {
    const locale = i18n.language === 'uk' ? 'uk-UK' : 'uk-UK';
    const options = { weekday: 'short', day: 'numeric', month: 'long' };
    return new Intl.DateTimeFormat(locale, options).format(date);
  };

  const handleDrop = (info) => {
    const dateFrom = info.date;
    const dateTo = new Date(dateFrom.getTime() + (60 * 60 * 1000));
    const apartmentId = info.draggedEl.getAttribute('data-id');
    const title = info.draggedEl.getAttribute('data-title');
    // const title = info.draggedEl.getElementsByClassName('apartmentTitle')[0].innerText;
    dispatch(calendarActions.create({ dateFrom, dateTo, apartment: {id: apartmentId}, title, client: {id: clientId}}));
  }

  return <Grid className="calendar widget 90" item xs={3}
               sx={(theme) => ({
                 backgroundColor: "newGrey.veryLight",
                   borderRadius: "4px",
                   boxShadow: "0px 0px 10px 2px rgba(1, 0, 34, 0.05)",
                   p: 2.5,
                 [theme.breakpoints.up("md")]: {
                   borderRadius: `${4 * scaleFactor / 16}vw`,
                   boxShadow: `0px 0px ${10 * scaleFactor / 16}vw ${2 * scaleFactor / 16}vw rgba(1, 0, 34, 0.05)`,
                   p: `${2.5 * 8 * scaleFactor / 16}vw`,
                   position: 'relative',
                   paddingTop: `${40 * scaleFactor / 16}vw !important`,
                   paddingLeft: `${20 * scaleFactor / 16}vw !important`,
                   height: `calc(100vh - ${105 * scaleFactor / 16}vw) !important`,
                 },
               })}
  >
            <Typography variant="h3"
                        sx={(theme) => ({
                          textAlign: "center",
                          [theme.breakpoints.up("md")]: {
                            fontSize: `${20 * scaleFactor / 16}vw`,
                          },
                        })}
            >{t('calendar')}</Typography>
            <Box sx={(theme) => ({
                   display: "flex",
                   justifyContent: "space-between",
                   alignItems: "center", mb: 2,
                   [theme.breakpoints.up("md")]: {
                      mb: `${2 * 8 * scaleFactor / 16}vw`,
                   },
                 })}
            >
              <IconButton onClick={handlePrevDay}
                          sx={(theme) => ({
                            backgroundColor: "unset", '&:hover': { backgroundColor: "unset" },
                            [theme.breakpoints.up("md")]: {
                              padding: `${8 * scaleFactor / 16}vw`,
                              fontSize: `${24 * scaleFactor / 16}vw`,
                            },
                          })}
              ><ArrowLeftIcon  sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                  width: `${24 * scaleFactor / 16}vw`,
                  height: `${24 * scaleFactor / 16}vw`,
                 fontSize: `${24 * scaleFactor / 16}vw`,
                },
              })}/></IconButton>
              <Box
                  sx={(theme) => ({
                    p: "6px", borderRadius: "30px", backgroundColor: "newGrey.medium2", width: "60%", textAlign: "center",
                      display: 'flex',
                      justifyContent: 'center',
                        [theme.breakpoints.up("md")]: {
                          padding: `${6 * scaleFactor / 16}vw`,
                          borderRadius: `${30 * scaleFactor / 16}vw`,
                    },
                  })}
              >
                <Typography variant="text"
                            sx={(theme) => ({
                              color: "dark",
                              fontSize: "14px",
                              letterSpacing: '0',
                              [theme.breakpoints.up("md")]: {
                                  fontSize: `${14 * scaleFactor / 16}vw`,
                              },
                            })}
                >{formatDate(currentDate)}</Typography>
              </Box>
              <IconButton onClick={handleNextDay}
                          sx={(theme) => ({
                            backgroundColor: "unset", '&:hover': { backgroundColor: "unset" },
                            [theme.breakpoints.up("md")]: {
                              padding: `${8 * scaleFactor / 16}vw`,
                             fontSize: `${24 * scaleFactor / 16}vw`,
                            },
                          })}
              ><ArrowRightIcon sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                  width: `${24 * scaleFactor / 16}vw`,
                  height: `${24 * scaleFactor / 16}vw`,
                  fontSize: `${24 * scaleFactor / 16}vw`,
                },
              })} /></IconButton>
            </Box>
            <StyledCalendar>
              <FullCalendar
                contentHeight="100"
                droppable={user.isCrmUser}
                drop={handleDrop}
                editable={user.isCrmUser}
                events={events}
                eventClick={handleEventClick}
                eventContent={renderEventContent}
                eventDrop={handleEventChange}
                eventResize={handleEventChange}
                initialView='timeGridDay'
                height="100%"
                headerToolbar={false}
                locale={i18n.language === 'uk' ? 'uk' : 'uk'}
                plugins={[timeGridPlugin, interactionPlugin]}
                ref={calendarRef}
                select={handleSelect}
                selectable={user.isCrmUser}
                slotMinTime="07:00:00"
                slotMaxTime="22:00:00"
                slotDuration="00:15:00"
                // slotLabelInterval="01:00:00"
                slotLabelFormat={{
                  hour: 'numeric',
                  minute: 'numeric',
                }}
                weekends={true}
              />
            </StyledCalendar>
            {open && <EventDialog open={open} event={event} handleClose={() => setOpen(false)} />}
          </Grid>
}

export function renderEventContent(eventInfo) {
  return (
      <Box sx={(theme) => ({
        color: "text.white",
        display: "flex",
        fontSize: "14px",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        lineHeight: "22px",
        overflow: "hidden",
        [theme.breakpoints.up("md")]: {
          fontSize: `${(14 * useResize()) / 16}vw`,
          lineHeight: `${(22 * useResize()) / 16}vw`,
        },
      })}>
        {/* <Box sx={{ fontWeight: 500 }}>{eventInfo.timeText}</Box> */}
        <Box>{eventInfo.event.title}</Box>
      </Box>
  );
}

export const StyledCalendar = styled(Box)(({ theme }) => {
  const scaleFactor = useResize();
  return {
    height: "calc(100% - 90px)",
    paddingTop: "10px",
    [theme.breakpoints.up("md")]: {
      height: `calc(100% - ${(90 * scaleFactor) / 16}vw)`,
      paddingTop: `${(10 * scaleFactor) / 16}vw`,
    },
    '& thead': {
      display: 'none'
    },
    '& td': {
      border: 'none',
    },
    '& .fc .fc-timegrid-col': {
      background: "transparent",
      backgroundColor: "transparent"
    },
      '& .fc table': {
        fontSize: '16px',
          [theme.breakpoints.up("md")]: {
              fontSize: `${(16 * scaleFactor) / 16}vw`,
          },
      },
    '& .fc .fc-cell-shaded': {
      background: "transparent",
      backgroundColor: "transparent"
    },
    '& .fc .fc-col-header ': {
      background: "transparent",
      backgroundColor: "transparent"
    },
    '& .fc-scrollgrid': {
      border: 'none',
    },
    '& .fc-scrollgrid-section > td': {
      border: 'none',
    },
    '& .fc-timegrid-slot-lane': {
      borderTop: `2px solid ${theme.palette.newGrey.light}`,
      [theme.breakpoints.up("md")]: {
        borderTop: `${(2 * scaleFactor) / 16}vw solid ${theme.palette.newGrey.light}`,
        height: `${(24 * scaleFactor) / 16}vw`,
      },
    },
    '& .fc-timegrid-slot-minor': {
      borderTopStyle: "none"
    },
    '& .fc-timegrid-slot-label': {
      fontSize: "14px",
      width: "100px",
      color: theme.palette.black,
      [theme.breakpoints.up("md")]: {
        fontSize: `${(14 * scaleFactor) / 16}vw`,
        width: `${(100 * scaleFactor) / 16}vw`,
        height: `${(24 * scaleFactor) / 16}vw`,
      },
      '& .fc-timegrid-slot-label-cushion': {
        position: "relative",
        top: "-12px",
        [theme.breakpoints.up("md")]: {
          top: `${(-12 * scaleFactor) / 16}vw`,
            padding: `0px ${(24 * scaleFactor) / 16}vw 0 ${(4 * scaleFactor) / 16}vw`
        },
      }
    },
    '& [data-time="07:00:00"]': {
      display: "none",
    },
    '& .fc-event': {
      border: "none",
      boxShadow: "none"
    }
  };
});
