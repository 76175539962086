import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientBoardApi, apartmentBoardApi } from "helpers";

const name = "clientBoard";
const initialState = {list: [], client: { list: [], client: {} }};
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const clientBoardActions = { ...slice.actions, ...extraActions };
export const clientBoardReducer = slice.reducer;

function createReducers() {
  return {
    handleInternalDrop, 
    handleExternalDrop, 
    // updateApartment, 
    // replaceApartmentBoards, 
    // replaceApartment, 
    handleInternalSort
  };

  function handleInternalSort(state, { payload }) {

    state.list[payload.boardIndex].clients = payload.data;
  }

  function handleInternalDrop(state, { payload }) {

    if (payload.dragIndex !== null) {
      state.list[payload.boardIndex].clients.splice(payload.dragIndex, 1);
    }
    
    if (payload.hoverIndex !== null) {
      state.list[payload.boardIndex].clients.splice(payload.hoverIndex, 0, payload.payload);
    }
  
    const newOrder = {};
    state.list[payload.boardIndex].clients.filter((client) => client.status === 1).map((client, index) => {
      if (client.status) {
        newOrder[index] = client.orderPosition;
      }
    })
  
    return state;
  }

  function handleExternalDrop(state, { payload }) {

    if (payload.removedIndex !== null) {

      state.list[payload.boardIndex].clients.splice(payload.removedIndex, 1);
    }

    if (payload.addedIndex !== null) {

      state.list[payload.boardIndex].clients.splice(payload.addedIndex, 0, payload.payload);
    }
  
    return state;
  }

  // function updateApartment(state, { payload }) {
  //   state.map(apartmentBoard => {
  //       apartmentBoard.apartments = apartmentBoard.apartments.map(apartment => {
  //       if (apartment.id === payload.id) {
  //           apartment = payload;
  //       }

  //       return apartment;
  //     })
  //     return apartmentBoard;
  //   })
  // }

  // function replaceApartmentBoards(state, { payload }) {
  //   state.splice(payload.hoverIndex, 0, state.splice(payload.dragIndex, 1)[0]);
  // }

  // function replaceApartment(state, { payload }) {
  //   const { dragIndex, hoverIndex, item, apartmentBoardId, toHead } = payload;
  //   const from = state.find(apartmentBoard => apartmentBoard.id === item.apartmentBoardId);
  //   const to = state.find(apartmentBoard => apartmentBoard.id === apartmentBoardId);

  //   let removeItem;
  //   if (item.isHead) {
  //     removeItem = from.head;
  //     from.head = null;
  //   } else {

  //     removeItem = from.apartments.find((apartment) => {
  //       if (apartment.id === item.id) {
  //         from.apartments.splice(dragIndex, 1)
  //       }

  //       return apartment.id === item.id;
  //     })
  //   }

  //   if (removeItem) {
  //     if (toHead) {
  //       if (to.head) {
  //         to.apartments.splice(hoverIndex, 0, to.head);
  //       }
  //       to.head = removeItem;
  //     } else {
  //       to.apartments.splice(
  //           apartmentBoardId === item.apartmentBoardId && !item.isHead && hoverIndex > item.index ? hoverIndex - 1 : hoverIndex,
  //         0,
  //         removeItem
  //       );
  //     }
  //   }
  // }
}

function createExtraActions() {
  return {
    list: list(),
    clientAll: clientAll(),
    updateOrder: updateOrder(),
    updateAll: updateAll(),
    moveClientInternal: moveClientInternal(),
    moveClientExternal: moveClientExternal(),
    deleteFromBoard: deleteFromBoard(),
  };

  function deleteFromBoard() {
    return createAsyncThunk(
      `${name}/deleteFromBoard`, async (data) => await clientBoardApi.deleteFromBoard(data)
    )
  }
  
  function moveClientInternal() {
    return createAsyncThunk(`${name}/moveClientInternal`, async (data, thunkAPI) => {

        const state = thunkAPI.getState();
        const newOrder = [];

        const currentBoard = state.clientBoard.list.find((board) => board.type === data.moveAnBoard);

        currentBoard.clients.filter((client) => client.status === 1).map((client, index) => {
          if(client.status) {
            newOrder[index] = client.id;
          }
        })
  
        return await clientBoardApi.moveClientInternal({board: currentBoard.id, data: newOrder})

    })
  }

  function moveClientExternal() {
    return createAsyncThunk(`${name}/moveClientExternal`, async (data, thunkAPI) => {

      const state = thunkAPI.getState();

      const newOrder = [];

      const currentBoard = state.clientBoard.list.find((board) => board.type === data.moveAnBoard);
      
      currentBoard.clients.map((client, index) => {
        newOrder[index] = client.id;
      })

      return await clientBoardApi.moveClientExternal({board: currentBoard.id, client: data.clientId, newIndex: data.newIndex, data: newOrder})

    })
  }

  function list() {
    return createAsyncThunk(`${name}/list`, async (data) => await clientBoardApi.list(data));
  }

  function clientAll() {
    return createAsyncThunk(`${name}/clientAll`, async (clientId) => await clientBoardApi.clientAll(clientId));
  }

  function updateOrder() {
    return createAsyncThunk(`${name}/updateOrder`, async (data, thunkAPI) => {
      const state = thunkAPI.getState();
      const newOrder = {};
      state.clientBoard.map((clientBoard, index) => {
        newOrder[clientBoard.id] = index + 1;
        return newOrder;
      })

      return await clientBoardApi.updateOrder(newOrder)
    });
  }

  function updateAll() {
    return createAsyncThunk(`${name}/updateClientBoards`, async (data, thunkAPI) => {
      const state = thunkAPI.getState();
      const newClientBoards = {};
      state.clioentBoard.forEach(clientBoard => {
        newClientBoards[clientBoard.id] = {};
        newClientBoards[clientBoard.id].clients = {};
        newClientBoards[clientBoard.id].head = clientBoard.head?.id;
        clientBoard.clients.forEach((client, index) => {
            newClientBoards[client.id].clients[client.id] = index + 1;
        })
      })
      return await clientBoardApi.updateAll(newClientBoards)
    });
  }
}

function createExtraReducers() {
  return {
    ...list(),
    ...clientAll(),
    ...moveClientInternal(),
    ...moveClientExternal(),
    ...deleteFromBoard()
  };

  function deleteFromBoard() {
    const { fulfilled } = extraActions.deleteFromBoard;
    return {
      [fulfilled]: (state, action) => {

        const { clients, indexBoard, clientBoardId } = action.meta.arg;

          const old = state.list[indexBoard].clients;
          state.list[indexBoard].clients = old.filter((client) => !clients.includes(client.id));
      }
    };
  }

  function moveClientExternal() {
    const { fulfilled } = extraActions.moveClientExternal;
    return {
      [fulfilled]: (state, action) => {
        return;
      },
    };
  }

  function moveClientInternal() {
    const { fulfilled } = extraActions.moveClientInternal;
    return {
      [fulfilled]: (state, action) => {
        return;
      },
    };
  }

  function list() {
    const { fulfilled } = extraActions.list;
    return {
      [fulfilled]: (state, action) => {
        state.list = action.payload.result
      },
    };
  }

  function clientAll() {
    const { fulfilled } = extraActions.clientAll;
    return {
      [fulfilled]: (state, action) => {
        state.client.list = action.payload.result.clientBoards;
        state.client.client = action.payload.result.client;
      },
    };
  }
}
