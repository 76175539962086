import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import useResize from "../config/themes/responsive";

export const ScrolledGrid = styled(Grid)(({ theme }) => {
  const scaleFactor = useResize();
  return {
    flexWrap: "nowrap",
    overflowX: "scroll",
    height: "calc(100% - 20px)",
    [theme.breakpoints.up("md")]: {
      height: `calc(100% - ${(20 * scaleFactor) / 16}vw)`,
      width: `calc(100% + ${(20 * scaleFactor) / 16}vw)`,
      marginLeft: `${(-20 * scaleFactor) / 16}vw`
    },
    '&::-webkit-scrollbar': {
      width: `${(12 * scaleFactor) / 16}vw`,
      ['-webkit-appearance']: 'none'
    },
    '&::-webkit-scrollbar-track': {
      background: "rgba(137, 137, 137, 0.20)",
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: `${(6 * scaleFactor) / 16}vw`,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    scrollbarColor: `${theme.palette.primary.main} rgba(137, 137, 137, 0.20)`,
    scrollbarWidth: 'thin',
    '& *': {
      scrollbarWidth: 'auto',
      scrollbarColor: 'auto',
      '&::-webkit-scrollbar': {
        width: 'auto',
        background: 'auto',
      },
      '&::-webkit-scrollbar-track': {
        background: 'auto',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'auto',
        border: 'none',
      },
    },
  };
});
