import { Box, Skeleton, Table, TableHead, TableSortLabel, TableBody, TableCell, TableContainer, TablePagination, TableRow } from "@mui/material";
import { useRef, useEffect, useMemo, useState } from "react";
import { Checkbox } from "components";
import { SelectableTableHeader } from "./SelectableTableHeader";
import Paper from '@mui/material/Paper';
import { useTranslation } from "react-i18next";
import useResize from "../../config/themes/responsive";

export function SelectableTable({handleRowClick, headCells, loading, isSelectable, rows, rowsPerPage = 10, totalCountRow, selected, setSelected, currentPreviewRow, setCurrentPreviewRow, size = "small", setPrevFlag, currentCurrency, setCurrentCurrency, 

  focusedRowIndex,
  setFocusedRowIndex,
  tableRefs

}) {

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('price');
  const [sortedRows, setSortedRows] = useState(rows);
  const [page, setPage] = useState(0);
  const { t } = useTranslation(["translation", "form"]);
  const tableRef = useRef(null)
  const scaleFactor = useResize();

  const vw = (px) => `${(px * scaleFactor) / 16}vw`;

  useEffect(() => {
    const sorted = stableSort(rows, getComparator(order, orderBy))

    const currentRow = sortedRows[focusedRowIndex];

    if (currentRow !== undefined) {

      const newIndex = sorted.findIndex(row => row.id === currentRow.id);
      setSortedRows(sorted);
      setFocusedRowIndex(newIndex);

      if (focusedRowIndex === 0) {
        handleRowClick(sorted[0].id);
        setCurrentPreviewRow(sorted[0].id);
      }

    } else {

      setFocusedRowIndex(0);
      handleRowClick(sortedRows[0].id);
      setCurrentPreviewRow(sortedRows[0].id);
    }

  }, [order, orderBy, rows]);

  const handleKeyDown = (event) => {

    // console.log(document.activeElement)

    if (document.activeElement.tagName === "LI") {
      // document.activeElement.blur();
      return;
    }
  
    if (document.activeElement.tagName === "INPUT") {
      return;
    }
  
    let newIndex = focusedRowIndex;

    if (event.code === "Space") {

      event.preventDefault();
      newIndex = Math.min(focusedRowIndex + 1, sortedRows.length - 1);
      handleClick(event, sortedRows[focusedRowIndex].id)
    }
  
    if (event.code === "ArrowDown") {

      event.preventDefault();
      newIndex = Math.min(focusedRowIndex + 1, sortedRows.length - 1);
    }
  
    if (event.code === "ArrowUp") {

      event.preventDefault();
      newIndex = Math.max(focusedRowIndex - 1, 0);
    }

    if (newIndex !== focusedRowIndex) {

      setFocusedRowIndex(newIndex);
      handleRowClick(sortedRows[newIndex].id);
      setCurrentPreviewRow(sortedRows[newIndex].id);
      isElementVisible();
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (!isSelectable) {
      return;
    }
    if (event.target.checked) {
      const newSelected = sortedRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isElementVisible = () => {

    const container = tableRef.current;

    const element = container.querySelector(
      `[data-row="${focusedRowIndex}"]`
    );

    if (!element || !container) {
      return false;
    }

    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    if ( (elementRect.bottom + elementRect.height) >= containerRect.bottom) {
      container.scrollTop = container.scrollTop + elementRect.height
    }

    if ( (elementRect.top - (elementRect.height * 2)) <= containerRect.top) {
      container.scrollTop = container.scrollTop - elementRect.height
    }
  }

  useEffect(() => {
    if (tableRefs.current[focusedRowIndex]) {
      tableRefs.current[focusedRowIndex].focus();
    }
  }, [focusedRowIndex]);

  const documentFocus = (event) => {
    event.preventDefault();
    if (tableRefs.current[focusedRowIndex]) {
      tableRefs.current[focusedRowIndex].focus();
    }
  }

  // useEffect(() => {
  //   document.addEventListener('keydown', documentFocus);
  //   return () => {
  //     document.removeEventListener('keydown', documentFocus);
  //   }
  // }, [currentPreviewRow])

  const handleClick = (event, name) => {
    if (!isSelectable) {
      return
    }
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalCountRow) : 0;
  const visibleHeadCells = useMemo(() => headCells.filter(cell => !cell.hasOwnProperty('visible') || cell.visible),
    [headCells]);

  return (
    <Box sx={{ width: '100%', overflowX: 'auto', height: "98%"}}>
      <TableContainer 
        ref={tableRef}
        component={Paper} 
        onKeyDown={handleKeyDown}
        role="table"
        tabIndex={0}
        sx={(theme) => ({
          maxHeight: "98%",
          flexWrap: "nowrap",
          overflowX: "scroll",
          height: "100%",
          '&::-webkit-scrollbar': {
            width: '12px',
            ['-webkit-appearance']: 'none'
          },
          '&::-webkit-scrollbar-track': {
            background: "rgba(137, 137, 137, 0.20)",
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#59C8FA', // #59C8FA;
            borderRadius: '6px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#1C77FF', // #1C77FF
          },
          scrollbarColor: `#59C8FA rgba(137, 137, 137, 0.20)`,  // #59C8FA;
          scrollbarWidth: 'thin',
          '& *': {
            scrollbarWidth: 'auto',
            scrollbarColor: 'auto',
            '&::-webkit-scrollbar': {
              width: 'auto',
              background: 'auto',
            },
            '&::-webkit-scrollbar-track': {
              background: 'auto',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'auto',
              border: 'none',
            },
          },
        })}
       >
        <Table
          size={size}
          stickyHeader
        >
          <SelectableTableHeader
            headCells={visibleHeadCells}
            isSelectable={isSelectable}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={totalCountRow}
            focusedRowIndex={focusedRowIndex}
          />
          <TableBody>
            {loading ?
              [...Array(5)].map((_, index) =>
                  <TableRow
                      key={index}
                  >
                  {visibleHeadCells.map((_, index) =>
                      <TableCell
                          key={index}
                      >
                        <Skeleton />
                      </TableCell>)}
                </TableRow>
              )
              : sortedRows.map((row, index) => {
                const isItemSelected = isSelectable && isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    key={row.id}
                    ref={(el) => tableRefs.current[index] = el}
                    tabIndex={-1}
                    onFocus={() => setFocusedRowIndex(index)}
                    sx={{
                      cursor: 'pointer',
                    }}
                    hover={focusedRowIndex === index ? false : true}
                    data-row={index}
                    onClick={(event) => {
                      if (handleRowClick) {
                        setFocusedRowIndex(index)
                        setCurrentPreviewRow(sortedRows[index].id);
                        handleRowClick(sortedRows[index].id);
                      } else {
                        handleClick(event, sortedRows[index].id)
                      }
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                   /* sx={(theme) => ({
                      [theme.breakpoints.up("md")]: {

                      },
                    })}*/
                  >
                    {isSelectable && <TableCell
                      sx={(theme) => ({
                        marginLeft: 0,
                        marginRight: 0,
                        paddingLeft: 0,
                        paddingRight: 0,

                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: index === focusedRowIndex ? '#59C8FA' : (theme) => theme.palette.background.white,

                        borderLeftWidth: '1px',
                        borderLeftStyle: 'solid',
                        borderLeftColor: index === focusedRowIndex ? '#59C8FA' : (theme) => theme.palette.background.white,

                        borderBottomWidth: '1px',
                        borderBottomStyle: 'solid',
                        borderBottomColor: index === focusedRowIndex ? '#59C8FA' : (focusedRowIndex - 1 === index) ? (theme) => theme.palette.background.white : null,

                        borderTopLeftRadius: index === focusedRowIndex ? '4px' : '0px',
                        borderBottomLeftRadius: index === focusedRowIndex ? '4px' : '0px',
                        [theme.breakpoints.up("md")]: {
                          width: vw(24) + '!important',
                          paddingRight: vw(12) + '!important',
                          paddingLeft: vw(16) + '!important',
                          borderWidth: vw(1),
                        },
                      })}
                      padding="checkbox"
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClick(event, row.id)
                      }}
                    />
                  </TableCell>
                  }

                  {visibleHeadCells.map((headCell, key) => (
                    <TableCell
                      key={key}
                      align={headCell.numeric ? "center" : "left"}
                      sx={(theme) => ({
                        whiteSpace: 'nowrap',
                        marginLeft: 0,
                        marginRight: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: index === focusedRowIndex ? '#59C8FA' : (theme) => theme.palette.background.white,
                        borderRight: index === focusedRowIndex && (visibleHeadCells.length - 1) === key ? '1px solid #59C8FA' : null,
                        borderBottomWidth: '1px',
                        borderBottomStyle: 'solid',
                        borderBottomColor: index === focusedRowIndex ? '#59C8FA' : (focusedRowIndex - 1 === index) ? (theme) => theme.palette.background.white : null,
                        borderTopRightRadius: index === focusedRowIndex && (visibleHeadCells.length - 1) === key ? '4px' : '0px',
                        borderBottomRightRadius: index === focusedRowIndex && (visibleHeadCells.length - 1) === key ? '4px' : '0px',
                        [theme.breakpoints.up("md")]: {
                          borderWidth: vw(1),
                          padding: `${vw(6)} ${vw(0)}`,
                          lineHeight: vw(24),
                          fontSize: vw(14),
                          '& .MuiBox-root .MuiBox-root':{
                            width: vw(12),
                            height: vw(12),
                            marginRight: vw(10),
                            borderRadius: vw(12),
                          }
                        },
                      })}
                    >
                      {row[headCell.id]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function descendingComparator(a, b, orderBy) {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    // Handle numeric values
    const numA = parseFloat(valueA.replace(/[^0-9.-]+/g, ''));
    const numB = parseFloat(valueB.replace(/[^0-9.-]+/g, ''));
    if (!isNaN(numA) && !isNaN(numB)) {
      return numB - numA;
    }
  }

  // Handle non-numeric values or mixed types
  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}