import { MenuItem, Typography } from "@mui/material";
import React from 'react';
import { useTranslation } from "react-i18next";
import { ContextMenu } from "./ContextMenu";
import useResize from "../../config/themes/responsive";

export function SortMenuClientBoard({ anchor, anchorHandler, setSort }) {

    const { t } = useTranslation(["translation", "form"]);
    const scaleFactor = useResize();

    return (
        <ContextMenu
            anchorEl={anchor}
            open={Boolean(anchor)}
            onClose={() => anchorHandler(null)}
        >
            <MenuItem onClick={() => {
                    anchorHandler(null);
                    setSort({ field: "date", direction: "asc" })
                }} 
                sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        "&.MuiMenuItem-gutters.MuiMenuItem-root": {
                            fontSize: `${12 * scaleFactor / 16}vw`,
                            lineHeight: `${18 * scaleFactor / 16}vw`,
                            padding: `${10 * scaleFactor / 16}vw ${40 * scaleFactor / 16}vw ${10 * scaleFactor / 16}vw ${20 * scaleFactor / 16}vw`
                        },
                    },
                })}
            >
                <Typography variant="text">{t('createdAtAsc')}</Typography>
            </MenuItem>
            <MenuItem onClick={() => {
                    anchorHandler(null);
                    setSort({ field: "date", direction: "desc" })
                }} 
                sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        "&.MuiMenuItem-gutters.MuiMenuItem-root": {
                            fontSize: `${12 * scaleFactor / 16}vw`,
                            lineHeight: `${18 * scaleFactor / 16}vw`,
                            padding: `${10 * scaleFactor / 16}vw ${40 * scaleFactor / 16}vw ${10 * scaleFactor / 16}vw ${20 * scaleFactor / 16}vw`
                        },
                    },
                })}
            >
                <Typography variant="text">{t('createdAtDesc')}</Typography>
            </MenuItem>
            <MenuItem onClick={() => {
                    anchorHandler(null);
                    setSort({ field: "name", direction: "asc" })
                }} 
                sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        "&.MuiMenuItem-gutters.MuiMenuItem-root": {
                            fontSize: `${12 * scaleFactor / 16}vw`,
                            lineHeight: `${18 * scaleFactor / 16}vw`,
                            padding: `${10 * scaleFactor / 16}vw ${40 * scaleFactor / 16}vw ${10 * scaleFactor / 16}vw ${20 * scaleFactor / 16}vw`
                        },
                    },
                })}
            >
                <Typography variant="text">{t('abcAsc')}</Typography>
            </MenuItem>
            <MenuItem onClick={() => {
                    anchorHandler(null);
                    setSort({ field: "name", direction: "desc" })
                }} 
                sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        "&.MuiMenuItem-gutters.MuiMenuItem-root": {
                            fontSize: `${12 * scaleFactor / 16}vw`,
                            lineHeight: `${18 * scaleFactor / 16}vw`,
                            padding: `${10 * scaleFactor / 16}vw ${40 * scaleFactor / 16}vw ${10 * scaleFactor / 16}vw ${20 * scaleFactor / 16}vw`
                        },
                    },
                })}
            >
                <Typography variant="text">{t('abcDesc')}</Typography>
            </MenuItem>
        </ContextMenu>
    );
}
