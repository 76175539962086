import { Radio as MuiRadio, radioClasses } from "@mui/material";
import { ReactComponent as UncheckedIcon } from "assets/radio_unchecked.svg";
import { ReactComponent as CheckedIcon } from "assets/radio_checked.svg";
import useResize from "../../config/themes/responsive";

export const Radio = ({ children, checked, ...props }) => {
  const scaleFactor = useResize();

  const vw = (px) => `${(px * scaleFactor) / 16}vw`;

  return (
      <MuiRadio
          sx={(theme) => ({
            color: "newGrey.light",
            [`&.${radioClasses.checked}`]: { color: "background.paper" },
            [theme.breakpoints.up("md")]: {
              padding: vw(9),
              '& .unchecked-icon rect': { fill: 'rgb(241, 241, 241)' },
              '& .PrivateSwitchBase-input': {
                width: vw(36),
                height: vw(36),
              },
              '& svg': {
                width: vw(18),
                height: vw(18),
              },
            },
          })}
          icon={<UncheckedIcon className="unchecked-icon" />}
          checkedIcon={<CheckedIcon />}
          checked={checked}
          {...props}
      >
        {children}
      </MuiRadio>
  );
};