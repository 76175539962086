import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from 'react';
import { calendarApi } from "helpers";
import { useDispatch } from "react-redux";
import { calendarActions } from "store";
import useResize from "../../config/themes/responsive";

export const SubmitResetGroupCustom = ({
  addLabel,
  cancelLabel = null,
  disabled,
  handleClick,
  handleClose,
  loading,
  formik
}) => {
  const { t } = useTranslation(["translation"]);
  const dispatch = useDispatch();
  const scaleFactor = useResize();

  const deleteCalendarItem = () => {
    calendarApi.delete(formik.values.id).then((result) => {
      dispatch(calendarActions.all()).then(() => {

        handleClose();
      });
    });
  }

  return <div>
    <LoadingButton endIcon={<></>} color="primary" disabled={disabled} loading={loading} onClick={handleClick}
                   sx={(theme) => ({
                       display: 'block',
                       margin: '0px auto',
                       marginTop:"50px",
                       width: '100%',
                       maxWidth: '400px',
                       [theme.breakpoints.up("md")]: {
                           marginTop: `${50 * scaleFactor / 16}vw`,
                           maxWidth: `${400 * scaleFactor / 16}vw`,
                           fontSize:  `${14 * scaleFactor / 16}vw`,
                           lineHeight: `${28 * scaleFactor / 16}vw`,
                           minWidth: `${64 * scaleFactor / 16}vw`,
                           padding: `${6 * scaleFactor / 16}vw ${16 * scaleFactor / 16}vw`,
                           borderRadius: `${4 * scaleFactor / 16}vw`,
                           boxShadow: `0px ${3 * scaleFactor / 16}vw ${1 * scaleFactor / 16}vw ${-2 * scaleFactor / 16}vw rgba(0, 0, 0, 0.2), 0px ${2 * scaleFactor / 16}vw ${2 * scaleFactor / 16}vw 0px rgba(0, 0, 0, 0.14), 0px ${1 * scaleFactor / 16}vw ${5 * scaleFactor / 16}vw 0px rgba(0, 0, 0, 0.12)`,
                       },
                   })}
      type="submit"
      variant="contained" >
      {addLabel}
    </LoadingButton>

    <Button color="primary"
          disabled={disabled}
          type="button"
            sx={(theme) => ({
                display: 'block',
                margin: '0px auto',
                width: '100%',
                maxWidth: '400px',
                mt: 2,
                [theme.breakpoints.up("md")]: {
                    mt: `${2 * 8 * scaleFactor / 16}vw`,
                    maxWidth: `${400 * scaleFactor / 16}vw`,
                    fontSize:  `${14 * scaleFactor / 16}vw`,
                },
            })}

          onClick={() => {deleteCalendarItem();}}
        >
          <span className="customButtonColor">{t('delete')}</span>
        </Button>

    <Button disabled={loading} type="reset"
            sx={(theme) => ({
                display: 'block',
                margin: '0px auto',
                mt: 2,
                width: '100%',
                maxWidth: '400px',
                color: (theme) => theme.palette.dark,
                [theme.breakpoints.up("md")]: {
                    mt: `${2 * 8 * scaleFactor / 16}vw`,
                    maxWidth: `${400 * scaleFactor / 16}vw`,
                    fontSize:  `${14 * scaleFactor / 16}vw`,
                },
            })}
      onClick={handleClose} >
      {cancelLabel !== null ? cancelLabel : t('cancelTitle')}
    </Button>
  </div>
}
