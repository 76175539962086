import ApiDefault from "./ApiDefault";

const PATH = 'v1/payments';

export default class Payment extends ApiDefault {

  async list(page) {
    return this.get(PATH + "/" + page);
  }

  async getForm() {
    return this.get(PATH + '/get/form')
  }

  async getFormBalance(data) {
    return this.patch(PATH + '/get/form-balance', data)
  }

  async setFormBalance(data) {
    return this.post(PATH + '/set/form-balance', data)
  }

  async setForm(data) {
    return this.post(PATH, data)
  }
}