import { Box, Grid, Tabs, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { AddButton } from "../buttons";
import { useTranslation } from "react-i18next";
import { Tab } from 'components';
import React from "react";
import useResize from "../../config/themes/responsive";

export function PageHeader(
    {
        tabValue,
        tabSetValue,
        handleClickOpen,
        title,
        secondTabName,
        children,
    }) {
    const { t } = useTranslation(["translation", "form"]);
    const { user } = useSelector((x) => x.auth);
    const scaleFactor = useResize();

    return (
        <Grid
            item
            xs={12}
            sx={(theme) => ({
                position: "sticky",
                height: { xs: 64, xl: 80 },
                zIndex: 10,
                [theme.breakpoints.up("md")]: {
                    '&.content--header': {
                        height: `${64 * scaleFactor / 16}vw`,
                        paddingLeft: `${20 * scaleFactor / 16}vw !important`,
                    }
                },
            })}
            className={"content--header"}
        >
            <Box
                sx={(theme) => ({
                    backgroundColor: "transparent",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    height: { xs: 64, xl: 80 },
                    position: "relative",
                    [theme.breakpoints.up("md")]: {
                        '&.content--header-wrap': {
                            height: `${64 * scaleFactor / 16}vw`,
                        }
                    },
                })}
                className={"content--header-wrap"}
            >
                <Typography
                    variant="h2"
                    sx={(theme) => ({
                        marginRight: 2,
                        [theme.breakpoints.up("md")]: {
                            fontSize: `${24 * scaleFactor / 16}vw`,
                            marginRight: `${8 * scaleFactor / 16}vw`,
                        },
                    })}
                >
                    {title}
                </Typography>
                {handleClickOpen !== undefined && (
                    <AddButton onClick={handleClickOpen} disabled={!user.isCrmUser} />
                )}
                <Box
                    sx={(theme) => ({
                        display: "flex",
                        alignItems: "center",
                        height: { xs: 64, xl: 80 },
                        position: "absolute",
                        right: 0,
                        [theme.breakpoints.up("md")]: {
                            '&.content--header-wrap-tabs': {
                                height: `${64 * scaleFactor / 16}vw`,
                                right: `${16 * scaleFactor / 16}vw`,
                            }
                        },
                    })}
                    className={"content--header-wrap-tabs"}
                >
                    {children}
                    {tabSetValue !== undefined && (
                        <Tabs
                            sx={(theme) => ({
                                display: "flex",
                                minHeight: "unset",
                                [theme.breakpoints.up("md")]: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    minHeight: `${48 * scaleFactor / 16}vw`,
                                },
                            })}
                            value={tabValue}
                            onChange={(e, value) => tabSetValue(value)}
                        >
                            <Tab
                                label={t("active")}
                                value={1}
                                sx={(theme) => ({
                                    fontSize: "14px",
                                    [theme.breakpoints.up("md")]: {
                                        fontSize: `${14 * scaleFactor / 16}vw`,
                                        maxWidth: `${360 * scaleFactor / 16}vw`
                                    },
                                })}
                            />
                            <Tab
                                label={secondTabName}
                                value={0}
                                sx={(theme) => ({
                                    marginLeft: { xs: 2.8 },
                                    [theme.breakpoints.up("md")]: {
                                        fontSize: `${14 * scaleFactor / 16}vw`,
                                        marginLeft: `${3.5 * 8 * scaleFactor / 16}vw`,
                                    },
                                })}
                            />
                        </Tabs>
                    )}
                </Box>
            </Box>
        </Grid>
    );
}
