import ApiDefault from "./ApiDefault";

const PATH = 'v1/comments';

export default class Comment extends ApiDefault {

    async create(data) {
        return this.post(PATH, data);
    }

    async list(data) {
        return this.get(PATH + `?id=${data.id}&type=${data.type}&page=${data.page}`);
    }
}
