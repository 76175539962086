import { FormControl, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import useResize from "../../config/themes/responsive";

export const LabeledAsyncSelect = ({ async, field, formik, label, placeholder, required }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(null);
  const loading = open && options === null;
  const { t } = useTranslation(["translation", "form"]);
  const scaleFactor = useResize();
  const vw = (px) => `${(px * scaleFactor) / 16}vw`;

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    async().then(({ result }) => {
      setOptions(result.map(row => {
        row.label = t(row.label);
        return row;
      }))
    });
  }, [loading, async]);

  const handleChangeValuePreview = (event, newValue) => {

    if (event) {

      if (JSON.parse(localStorage.getItem('currentPreview'))) {
        const apartmentsList = JSON.parse(localStorage.getItem('currentPreview'));

        const currentApartment = apartmentsList.map(apartment => {
          if (apartment.id === formik.values.id) {
            return {...apartment, [field]: newValue}
          }
          return apartment;
        })

        localStorage.setItem('currentPreview', JSON.stringify(currentApartment))
      }
    }
  }

  const handleChange = (event, newValue) => {
    formik.setFieldValue(field, newValue, true)
    handleChangeValuePreview(event, newValue)
  }

  return <FormControl fullWidth>
    <Typography
        className={required && "label"}
        variant="h4"
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            marginTop: vw(30),
            fontSize: vw(14),
            lineHeight: vw(22),
          },
        })}
    >
      {label}
    </Typography>
    <Autocomplete
      fullWidth
      open={open}
      // onChange={(e, newValue) => formik.setFieldValue(field, newValue, true)}
      onChange={(event, newValue) => handleChange(event, newValue)}
      onOpen={() => {setOpen(true);}}
      onClose={() => {setOpen(false);}}
      blurOnSelect={true}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={(option) => option.label}
      options={options || []}
      loading={loading}
      className={'050'}
      renderInput={(params) => (
        <TextField
            className={'060'}
          {...params}
          error={formik.errors[field] && formik.touched[field]}
          helperText={formik.touched[field] && formik.errors[field]}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress className={'040'} color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              padding: 0
            },
          }}
          placeholder={placeholder}
          variant="filled"
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                '& .MuiFilledInput-root': {
                  fontSize: vw(16),
                  lineHeight: vw(23),
                },
                '& .MuiFilledInput-input': {
                  padding: vw(12),
                  fontSize: vw(14),
                  lineHeight: vw(22),
                  height: vw(22),
                },
                '&.field-label-tab2 .MuiInputBase-root .MuiInputAdornment-root': {
                  marginLeft: vw(8),
                },
                '&.field-label-tab2 .MuiInputBase-root .MuiInputAdornment-root p': {
                  fontSize: vw(16),
                  lineHeight: vw(24)
                },
                '& p.MuiFormHelperText-root.Mui-error': {
                  fontSize: vw(14),
                  marginLeft: vw(14),
                  marginRight: vw(14),
                  marginTop: vw(3),
                  lineHeight: vw(26),
                }
              },
            })}
        />
      )}
      sx={(theme) => ({
        [theme.breakpoints.up("md")]: {
          marginTop:  vw(8),
          marginBottom: vw(4),
          '& .MuiInputBase-root': {
            borderTopLeftRadius: vw(4),
            borderTopRightRadius: vw(4),
            fontSize: vw(16),
            lineHeight:  vw(23),
            padding: `${12 * scaleFactor / 16}vw ${39 * scaleFactor / 16}vw ${12 * scaleFactor / 16}vw ${12 * scaleFactor / 16}vw`,
            '&:after, &:before': {
              borderBottom: `${1 * scaleFactor / 16}vw solid rgba(255, 255, 255, 0.7)`
            },
            '& .MuiInputBase-input': {
              padding:  vw(12),
              fontSize: vw(14),
              lineHeight: vw(22),
              height: vw(22),
            },
            '& .MuiAutocomplete-endAdornment': {
              right: vw(9),
              top: `calc(50% - ${20 * scaleFactor / 16}vw)`,
            },
            '& .MuiAutocomplete-endAdornment button:first-of-type': {
              marginRight: vw(-2),
            },
            '& .MuiAutocomplete-endAdornment button': {
              fontSize: vw(24),
              padding: vw(8),
              '& svg': {
                width: vw(24),
                height: vw(24),
                fontSize: vw(24),
              }
            },
          }
        }
      })}
      variant="filled"
      value={formik.values[field]}
    />
  </FormControl>
}
