import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientApi } from "helpers";

const name = "client";
const initialState = [];
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const clientActions = { ...slice.actions, ...extraActions };
export const clientReducer = slice.reducer;

function createReducers() {
  return {
    replaceClients
  };

  function replaceClients(state, { payload }) {
    state.splice(payload.hoverIndex, 0, state.splice(payload.dragIndex, 1)[0]);
  }
}

function createExtraActions() {
  return {
    create: create(),
    update: update(),
    all: all(),
    updateOrder: updateOrder(),
  };

  function create() {
    return createAsyncThunk(`${name}/create`, async (data) => await clientApi.create(data));
  }

  function update() {
    return createAsyncThunk(`${name}/update`, async (data) => await clientApi.update(data));
  }

  function all() {
    return createAsyncThunk(`${name}/all`, async () => await clientApi.all());
  }

  function updateOrder() {
    return createAsyncThunk(`${name}/updateOrder`, async (data, thunkAPI) => {
      const state = thunkAPI.getState();
      const newOrder = {};
      state.client.forEach((client, index) => {
        newOrder[client.id] = index + 1;
      })

      return await clientApi.updateOrder(newOrder)
    });
  }
}

function createExtraReducers() {
  return {
    ...createClient(), ...updateClient(), ...all(),
  };

  function createClient() {
    const { fulfilled } = extraActions.create;
    return {
      [fulfilled]: (state, action) => {
        const client = action.meta.arg;
        client.id = action.payload.id;
        client.responsible = client.responsibleData;

        const index = state.findIndex(item => item.id === client.id);
        if (index === -1) {
          state.push(client);
        } else {
          state[index] = client;
        }
      },
    };
  }

  function updateClient() {
    const { fulfilled } = extraActions.update;
    return {
      [fulfilled]: (state, action) => {
        const client = action.meta.arg;
        client.id = action.payload.id;
        client.responsible = client.responsibleData;

        const index = state.findIndex(item => item.id === client.id);
        if (index === -1) {
          state.push(client);
        } else {
          state[index] = client;
        }
      },
    };
  }

  function all() {
    const { fulfilled } = extraActions.all;
    return {
      [fulfilled]: (state, action) => {
        localStorage.setItem('clientsTotal', action.payload.result.length);
        return action.payload.result;
      },
    };
  }
}
