import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { Checkbox } from "components";
import useResize from "../../config/themes/responsive";

export function SelectableTableHeader({ headCells, isSelectable, numSelected, onRequestSort, onSelectAllClick, order, orderBy, rowCount, focusedRowIndex }) {

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const scaleFactor = useResize();

  const vw = (px) => `${(px * scaleFactor) / 16}vw`;

  return (
    <TableHead>
      <TableRow>
        {isSelectable && <TableCell padding="checkbox"
        sx={(theme) => ({
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0,
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: focusedRowIndex === 0 ? (theme) => theme.palette.background.white : null,
            [theme.breakpoints.up("md")]: {
                width: vw(24) + '!important',
                borderBottomWidth: vw(1),
                paddingRight: vw(12) + '!important',
                paddingLeft: vw(16) + '!important',
                lineHeight: vw(24),
                fontSize: vw(14),
            },
        })}

        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all',
            }}
            sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                    padding: vw(9),
                    '& .unchecked-icon rect': {
                        fill: 'rgb(241, 241, 241);',
                    },
                    '& .PrivateSwitchBase-input': {
                        width: vw(40),
                        height: vw(40),
                    },
                    '& svg': {
                        width: vw(18),
                        height: vw(18),
                    },
                },
            })}
          />
        </TableCell>
        }
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={(theme) => ({
              whiteSpace: 'nowrap',
              marginLeft: 0,
              marginRight: 0,
              paddingLeft: 0,
              paddingRight: 0,
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBottomColor: focusedRowIndex === 0 ? (theme) => theme.palette.background.white : null,
                [theme.breakpoints.up("md")]: {
                    borderBottomWidth: vw(1),
                    padding: `${vw(6)} ${vw(0)}`,
                    borderWidth: vw(1),
                    lineHeight: vw(24),
                    fontSize: vw(14),
                },
            })}
          >
            <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                      '& .MuiTableSortLabel-icon':{
                          width: vw(18),
                          height: vw(18),
                          fontSize: vw(18),
                          marginRight: vw(4),
                          marginLeft: vw(4),
                      }
                    },
                })}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}