import ApiDefault from "./ApiDefault";

const PATH = 'v1/subscriptions';

export default class Subscription extends ApiDefault {

    async changeUser(data) {
        return this.post(PATH + `/change-user/${data.id}`, data);
    }

    async changeDepartment(data) {
        return this.post(PATH + `/change-department/${data.id}`, data);
    }

    async me(id) {
        return this.get(PATH + '/me');
    }

    async getList() {
        return this.get(PATH);
    }

    async update(data, id) {
        return this.patch(PATH + `/${id}`, data);
    }

    async delete(id) {
        return this.delete(PATH + `/${id}`);
    }
}
